import React from "react";
import { View, TouchableOpacity, Image } from "react-native";
import Colors from "../../constants/colors";
import { Ionicons } from "@expo/vector-icons";
import { scale, moderateScale, verticalScale } from "react-native-size-matters";
import Text from "../../components/utils/NotoFont";
import icons from "../../constants/icons";

export default function (props) {
  return (
    <View>
      <View
        style={{
          height: 56,
          flexDirection: "row",
          justifyContent: "flex-start",
          shadowColor: Colors.lightGray,
          shadowOffset: {
            width: 0,
            height: 2,
          },
          shadowOpacity: 0.1,
        }}
      >
        {/* Back or Logo */}
        {props.withBack ? (
          <View style={{ flex: 2, flexDirection: "row" }}>
            <View
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={() => {
                  props.navigation.goBack();
                }}
                style={{
                  marginLeft: 20,
                }}
              >
                <Image
                  source={icons.goBack}
                  style={{ width: 20, height: 20, tintColor: Colors.black }}
                />
              </TouchableOpacity>
            </View>
            <View style={{ flex: 1 }}></View>
          </View>
        ) : (
          <View style={{ flex: 2 }}></View>
        )}
        {/* Title */}
        <View
          style={{
            flex: 4,
            alignSelf: "center",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {props.withLogo ? (
            <Image
              resizeMode="contain"
              source={require("../../assets/logo_thecheers.png")}
              style={{
                flex: 1,
                alignSelf: "center",
                width: 108,
                height: 24,
              }}
            />
          ) : (
            <Text
              numberOfLines={1}
              style={{
                fontSize: 16,
                alignSelf: "center",
                color: Colors.Gray,
              }}
              bold
            >
              {props.title}
            </Text>
          )}
        </View>
        {props.buttonB ? (
          <View style={{ flex: 2, flexDirection: "row" }}>
            <View
              style={{
                flex: 3,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TouchableOpacity
                onPress={props.onPressB}
                style={{
                  flex: 1,
                  alignItems: "flex-start",
                  justifyContent: "center",
                }}
              >
                {props.imageB ? (
                  <Image
                    source={props.buttonBActive ? props.imageBA : props.imageB}
                    style={{ width: props.size, height: props.size }}
                  />
                ) : (
                  <Text
                    style={{
                      fontSize: 14,
                      color: props.buttonBActive ? Colors.primary : Colors.gray,
                    }}
                    bold
                  >
                    {props.textB}
                  </Text>
                )}
              </TouchableOpacity>
            </View>
            <View style={{ flex: 0 }}></View>
          </View>
        ) : (
          <View style={{ flex: 2 }}></View>
        )}
        {/*         
        <View
          style={{
            alignSelf: "center",
            flexDirection: "row",
            flex: 2,
          }}
        >
          {props.buttonA ? (
            <View style={{ flex: 1 }}>
              <TouchableOpacity
                onPress={props.onPressA}
                style={{
                  flex: 1,
                  alignSelf: "center",
                  justifyContent: "center"
                }}
              >
                {props.imageA ?
                (<Image
                  source={props.imageA}
                  style={{ width: props.size, height: props.size }}
                /> ): (<Text style={{fontSize:14, color:Colors.gray}}>{props.textA}</Text>)
                }
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ flex: 1 }} />
          )}
          {props.buttonB ? (
            <View style={{ flex: 2 }}>
              <TouchableOpacity
                onPress={props.onPressB}
                style={{
                  flex: 1,
                  alignItems: "flex-start",
                  justifyContent: "center"
                }}
              >
                {props.imageB ?
                (<Image
                  source={props.imageB}
                  style={{ width: props.size, height: props.size }}
                /> ): (<Text style={{fontSize:14, color: props.buttonBActive? Colors.primary: Colors.gray}} bold>{props.textB}</Text>)
                }
              </TouchableOpacity>
            </View>
          ) : (
            <View style={{ flex: 1 }} />
          )}
        </View> */}
      </View>
    </View>
  );
}
