import React, { useState, useEffect,useContext } from "react";
import { StatusBar } from "expo-status-bar";
import {
  ScrollView,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  ActivityIndicator,
  TextInput,
  StyleSheet,
} from "react-native";
import Text from "../components/utils/NotoFont";
import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import env from "../constants/env";
import axios from "axios";
import Toast from "react-native-toast-message";
import { saveToken, getToken } from "../components/utils/Token";
import { AuthContext } from "../provider/AuthProvider";

export default function ({ navigation }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const { login } = useContext(AuthContext);

  useEffect(() => {

    getToken();
  });

  const handleLogin = (data) => {
    setLoading(true);
    fetch(`https://${env.apiUrl}/api/v1/user/login`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then(res => {
      res.headers.forEach((val, key) => {
        console.log(key, val)
    })

        console.log(res.headers);
        console.log(res.headers.get('authorization'));
        console.log(res.status);
        console.log(res);
        res.status == 400 ? Toast.show({
          type: "error",
          text1: "비밀번호가 일치하지 않습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position:'top',
        }) : (saveToken(res.headers.get('authorization')), login());
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          text1: "로그인에 실패했습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position:'top',
        });
        setLoading(false);
        console.error(error);
      });
  };

  const BottomButton = (props) => {
    return (
      <TouchableOpacity onPress={props.onPress}>
        <Text
          style={{
            color: Colors.gray,
            fontSize: 12,
            textDecorationLine: "underline",
          }}
        >
          {props.name}
        </Text>
      </TouchableOpacity>
    );
  };

  const BottomButtons = () => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <BottomButton
          onPress={() => {
            navigation.navigate("Register");
          }}
          name={"회원가입"}
        />
        <BottomButton
          onPress={() => {
            Toast.show({
              type: "error",
              text1: "준비중인 기능입니다...",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position:'top',
            });
          }}
          name={"ID/PW 찾기"}
        />
      </View>
    );
  };

  return (
    <Layout navigation={navigation} withLogo title="내 작업실" size={24}>
      <View style={styles.container}>
        <View style={{height:100}}></View>
        <KeyboardAvoidingView>
          <View>
        <Text style={styles.label} bold>
          {"이메일"}
        </Text>
        <View style={styles.textInputContainer}>
          <TextInput
            key={"email"}
            style={styles.textInput}
            placeholder="Email"
            placeholderStyle={{
              fontFamily: "NotoR",
            }}
            value={email}
            autoCapitalize="none"
            autoCompleteType="off"
            onChangeText={(text) => {
              setEmail(text);
            }}
          />
        </View>
        </View>
        <View>
        <Text style={styles.label} bold>
          {"비밀번호"}
        </Text>
        <View style={styles.textInputContainer}>
          <TextInput
            key={"password"}
            style={styles.textInput}
            placeholder="PW"
            placeholderStyle={{
              fontFamily: "NotoR",
            }}
            value={password}
            autoCapitalize="none"
            autoCompleteType="off"
            autoCorrect={false}
            secureTextEntry={true}
            onChangeText={(text) => setPassword(text)}
          />
        </View>
        </View>
        </KeyboardAvoidingView>

        <View>
          <TouchableOpacity
            onPress={() => {
              handleLogin({
                email: email,
                password: password,
                keep_logged_in: true,
              });
            }}
            disabled={loading}
            style={{
              marginTop: 22,
              width: 335,
              height: 56,
              backgroundColor: Colors.primary,
              borderRadius: 4,
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {loading ? (
              <ActivityIndicator size="large" color="#fff" />
            ) : (
              <Text style={styles.buttonText} bold>
                로그인
              </Text>
            )}
          </TouchableOpacity>
          <BottomButtons />
        </View>
      </View>
    </Layout>
  );
}
const styles = StyleSheet.create({
  container: {
    width: 335,
    top: 250,
    height: 500,
    alignSelf: "center",
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  textInputContainer: {
    width: 335,
    height: 50,
    backgroundColor: "#FFF",
    borderColor: "#d8d8d8",
    borderWidth: 1,
    borderRadius: 8,
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
  },
  textInput: {
    padding: 10,
    width: 335,
    height: 40,
    paddingHorizontal: 20,
    textAlign: "left",
    color: "black",
    fontSize: 14,
    flex: 1,
  },
  buttonText: {
    fontSize: 16,
    color: Colors.white,
    textAlign: "center",
  },
  footerContainer: {
    height: 100,
    top: -100,
  },
  footer: {
    width: "100%",
    fontSize: 40,
    textAlign: "center",
    alignContent: "center",
  },
  label: {
    color: Colors.gray,
    fontSize: 14,
    alignSelf: "flex-start",
    marginTop: 0,
  },
});
