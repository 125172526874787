import { StatusBar } from 'expo-status-bar';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import Colors from '../../constants/colors';
import TopNav from '../navigation/TopNav';
export default function (props) {
	return (
		<View style={styles.container}>
			{props.title && (
				<TopNav
					navigation={props.navigation}
					title={props.title}
					withLogo={props.withLogo ? true : false}
					size={props.size}
					withBack={props.withBack ? true : false}
					buttonA={props.buttonA ? true : false}
					buttonB={props.buttonB ? true : false}
					imageA={props.imageA ? props.imageA : false}
					imageB={props.imageB ? props.imageB : false}
					imageBA={props.imageBA ? props.imageBA : false}
					onPressA={props.onPressA ? props.onPressA : false}
					onPressB={props.onPressB ? props.onPressB : false}
					textA={props.textA ? props.textA : false}
					textB={props.textB ? props.textB : false}
					buttonBActive={props.buttonBActive ? props.buttonBActive : false}
				/>
			)}
			{/* this text using ubuntu font */}
			{props.children}
		</View>
	);
}

const styles = StyleSheet.create({
	container: {
		flex: 1,
		flexDirection: 'column',
		backgroundColor: Colors.background,
	},
});
