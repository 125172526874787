import {Platform} from 'react-native';
import base64 from 'base-64'; // 없는 경우 설치 : npm install base-64

export async function uploadMultipleImages(imagelist, uploadUrl) {
  // 1. initialize request
  const xhr = new XMLHttpRequest();
  // 2. open request
  xhr.open("POST", uploadUrl);
  // 3. set up callback for request
  xhr.onload = () => {
    const response = JSON.parse(xhr.response);

    console.log(response);
    // ... do something with the successful response
  };
  // 4. catch for request error
  xhr.onerror = (e) => {
    console.log(e, "upload failed");
  };
  // 4. catch for request timeout
  xhr.ontimeout = (e) => {
    console.log(e, "upload timeout");
  };
  // REPLACE #4 WITH THE CODE BELOW IF YOU'RE UPLOADING AN ARRAY OF MULTIPLE FILES
  // 4. create formData to upload
  const arrayOfFilesToUpload = imagelist;
  const formData = new FormData();

  arrayOfFilesToUpload.forEach((file) => {
    formData.append("file", {
      uri: file.uri, // this is the path to your file. see Expo ImagePicker or React Native ImagePicker
      type: `${type}/${fileEnding}`, // example: image/jpg
      name: `upload.${fileEnding}`, // example: upload.jpg
    });
  });
  // 6. upload the request
  xhr.send(formData);
  // 7. track upload progress
  if (xhr.upload) {
    // track the upload progress
    xhr.upload.onprogress = ({ total, loaded }) => {
      const uploadProgress = loaded / total;
      console.log(uploadProgress);
    };
  }
}


export async function uploadSingleImage(image, uploadUrl) {
  let formdata = new FormData();
  formdata.append('file_string', image.base64);
  console.log(image.base64);
  const result = await fetch(uploadUrl,{
    method: 'post',
    headers: {
      'enctype': 'multipart/form-data',
    },
    body: formdata
    }).then(response => response.json())
    .then((data) => {console.log(data.url); return data.url})
    .catch(err => {
      console.log("Error!");
      console.log(err)
    });
    return result;


}
  