import React from 'react';
import Colors from '../../constants/colors';
import { Text } from 'react-native';

export default (props) => {
	return (
		<Text
			style={{
				color: props.focused ? Colors.ActiveTab : Colors.inActiveTab,
				fontSize: 10,
			}}
		>
			{props.title}
		</Text>
	);
};
