const home = require('../assets/icon_home_in.png');
const home_active = require('../assets/icon_home_in.png');
const plus = require('../assets/icon_upload.png');
const plus_active = require('../assets/icon_upload_in.png');
const song = require('../assets/icon_song.png');
const song_active = require('../assets/icon_song.png');
const profile = require('../assets/icon_profile.png');
const profile_active = require('../assets/icon_profile_a.png');
const star = require('../assets/icon_star_in.png');
const star_active = require('../assets/icon_star_a.png');
const record = require('../assets/icon_record.png');
const pause = require('../assets/icon_pause.png');
const tempo = require('../assets/icon_tempo.png');
const favorite = require('../assets/icon_favorite.png');
const random = require('../assets/icon_random.png');
const pitch = require('../assets/icon_pitch.png');
const prev = require('../assets/icon_prev.png');
const backward = require('../assets/icon_backward.png');
const viewCount = require('../assets/icon_viewCount.png');
const newLabel = require('../assets/newLabel.png');
const recordBg = require('../assets/icon_recordBg.png');
const recordS = require('../assets/icon_recordS.png');
const more = require('../assets/icon_more.png');
const play = require('../assets/icon_play.png');
const chevron = require('../assets/icon_chevron.png');
const mySong = require('../assets/icon_mySong.png');
const myArtist = require('../assets/icon_myArtist.png');
const myFriends = require('../assets/icon_myFriends.png');
const reservedList = require('../assets/icon_reservedList.png');
const search = require('../assets/icon_search_in.png');
const search_active = require('../assets/icon_search_a.png');
const pin = require('../assets/icon_pin.png');
const date = require('../assets/icon_date.png');
const members = require('../assets/icon_members.png');
const membersVerified = require('../assets/icon_members_verified.png');
const community = require('../assets/icon_community.png');
const location = require('../assets/icon_location.png');
const shop = require('../assets/icon_shop.png');
const roundMore = require('../assets/icon_round_more.png');
const moreH = require('../assets/icon_more_h.png');
const like = require('../assets/icon_like.png');
const likeA = require('../assets/icon_like_a.png');
const comment = require('../assets/icon_comment.png');
const send = require('../assets/icon_send.png');
const heart = require('../assets/icon_heart.png');
const heartA = require('../assets/icon_heart_a.png');
const won = require('../assets/icon_won.png');
const map = require('../assets/icon_map.png');
const calendarCheck = require('../assets/icon_calendar_check.png');
const wine = require('../assets/icon_wine.png');
const tag = require('../assets/icon_tag.png');
const description = require('../assets/icon_description.png');
const arrowDown = require('../assets/icon_arrowDown.png');
const facebook = require('../assets/icon_facebook.png');
const instagram = require('../assets/icon_instagram.png');
const link = require('../assets/icon_link.png');
const camera = require('../assets/icon_camera.png');
const remove = require('../assets/icon_remove.png');
const down = require('../assets/icon_down.png');
const uploadText = require('../assets/icon_uploadText.png');
const uploadProfile = require('../assets/icon_uploadProfile.png');
const openchat = require('../assets/icon_openchat.png');
const trash = require('../assets/icon_trash.png');
const editCommunity = require('../assets/icon_editCommunity.png');
const editProfile = require('../assets/icon_editProfile.png');
const feed = require("../assets/icon_feed.png");
const numParticipants = require("../assets/icon_numParticipants.png");
const thickPin = require("../assets/icon_thickPin.png");
const goBack = require("../assets/icon_goBack.png");

export default {
  home,
  home_active,
  song,
  song_active,
  plus,
  plus_active,
  profile,
  profile_active,
  star,
  star_active,
  record,
  pause,
  tempo,
  favorite,
  random,
  pitch,
  prev,
  backward,
  viewCount,
  newLabel,
  recordBg,
  recordS,
  more,
  play,
  chevron,
  mySong,
  myArtist,
  myFriends,
  reservedList,
  search,
  search_active,
  pin,
  date,
  members,
  membersVerified,
  community,
  location,
  shop,
  roundMore,
  moreH,
  like,
  likeA,
  comment,
  send,
  heart,
  heartA,
  won,
  map,
  calendarCheck,
  wine,
  tag,
  description,
  arrowDown,
  facebook,
  instagram,
  link,
  camera,
  remove,
  down,
  uploadText,
  uploadProfile,
  openchat,
  trash,
  editProfile,
  editCommunity,
  feed,
  numParticipants,
  thickPin,
  goBack,
};
