import React, { useState, useEffect, useContext } from "react";
import { StatusBar } from "expo-status-bar";
import {
  ScrollView,
  TouchableOpacity,
  View,
  KeyboardAvoidingView,
  ActivityIndicator,
  TextInput,
  Image,
  StyleSheet,
  Platform,
} from "react-native";
import Text from "../components/utils/NotoFont";
import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import AppIntroSlider from "react-native-app-intro-slider";
import env from "../constants/env";
import axios from "axios";
import { saveToken, getToken } from "../components/utils/Token";
import Toast from "react-native-toast-message";
import { AuthContext } from "../provider/AuthProvider";
import icons from "../constants/icons";
import * as ImagePicker from "expo-image-picker";
import {uploadSingleImage} from "../components/utils/UploadImage";

export default function ({ navigation }) {
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [emailValidMsg, setEmailValidMsg] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [isRepeatPasswordValid, setIsRepeatPasswordValid] = useState(true);
  const [passwordValidMsg, setPasswrodValidMsg] = useState("");
  const [repeatPasswordValidMsg, setRepeatPasswrodValidMsg] = useState("");
  const [name, setName] = useState("");
  const [isNameValid, setIsNameValid] = useState(true);
  const [nameValidMsg, setNameValidMsg] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneValid, setIsPhoneValid] = useState(true);
  const [phoneValidMsg, setPhoneValidMsg] = useState("");
  const [loading, setLoading] = useState(true);
  const { login } = useContext(AuthContext);
  const [profileImage, setProfileImage] = useState();
  const [profileImageUrl, setProfileImageUrl] = useState();
  const [isAllValid, setIsAllValid] = useState(false);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const { status } =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("카메라 권한이 필요합니다!");
        }
      }
    })();
  }, [profileImage]);

  const checkIsAllValid = () => {
    console.log(isEmailValid);
    console.log(isRepeatPasswordValid);
    console.log(profileImageUrl);
    console.log(name);
    console.log(isPhoneValid);
    console.log(isAllValid);
    isEmailValid && isRepeatPasswordValid && name && isPhoneValid
      ? setIsAllValid(true)
      : setIsAllValid(false);
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64:true
    });

    if (!result.cancelled) {
      setProfileImage(result);
      let imageUrl = await uploadSingleImage(
        result,
        `https://${env.apiUrl}/api/v1/s3/upload/image`
      );
      checkIsAllValid();
      console.log(imageUrl);
      setProfileImageUrl(imageUrl);
    }
  };

  async function checkEmail() {
    console.log("Checking Email..");
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    let isValid = reg.test(email);
    isValid === false
      ? (setIsEmailValid(false), setEmailValidMsg("잘못된 이메일 형식입니다."))
      : (setIsEmailValid(true), setEmailValidMsg(""));

    await fetch(`https://${env.apiUrl}/api/v1/email-duplicate-check`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email: email }),
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        json.data == "This email is already in use."
          ? (setIsEmailValid(false),
            setEmailValidMsg("이미 사용중인 이메일 입니다."))
          : isValid
          ? (setIsEmailValid(true),
            setEmailValidMsg("사용가능한 이메일 입니다."))
          : "잘못된 이메일 형식입니다.";
      })
      .catch((error) => {
        setIsEmailValid(false);
        setEmailValidMsg("네트워크 오류 입니다.");
        console.log();
        console.error(error);
      });
  }

  const handleLogin = (data) => {
    fetch(`https://${env.apiUrl}/api/v1/user/login`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res.headers.get("authorization"));
        console.log(res.status);
        res.status == 400
          ? Toast.show({
              type: "error",
              text1: "비밀번호가 일치하지 않습니다.",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            })
          : (saveToken(res.headers.get("authorization")), login());
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          text1: "로그인에 실패했습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });
        console.error(error);
      });
  };

  async function handleSignup(data) {
    await uploadSingleImage(
      profileImage,
      `https://${env.apiUrl}/api/v1/s3/upload/image`
    );
    fetch(`https://${env.apiUrl}/api/v1/user/profile`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.status;
        console.log(res.status);
        res.status === 201
          ? (Toast.show({
              type: "success",
              text1: "회원가입 완료! 로그인 중...",
              visibilityTime: 1000,
              autoHide: true,
            }),
            handleLogin({
              email: email,
              password: password,
              keep_logged_in: true,
            }))
          : Toast.show({
              type: "error",
              text1: "이미 가입된 이메일 입니다!",
              visibilityTime: 1000,
              autoHide: true,
            });
      })
      .catch((error) => {
        Toast.show({
          type: "error",
          text1: "네트워크 에러 발생!",
          visibilityTime: 1000,
          autoHide: true,
        });

        console.error(error);
      });
  }

  useEffect(() => {}, []);

  const slides = [
    {
      key: "one",
      title: `계정으로 사용하실 \n이메일을 입력해 주세요.`,
      placeholder: "example@thecheers.com",
    },
    {
      key: "two",
      title: "비밀번호를\n입력해주세요.",
      text1: "비밀번호 (영문 / 숫자 조합 6~15자리)",
      placeholder1: "비밀번호를 입력해주세요",
      text2: "비밀번호 확인",
      placeholder2: "비밀번호를 다시 입력해주세요",
    },
    {
      key: "three",
      title: "프로필을 작성해 주세요",
      text0: "프로필 사진을 업로드해 주세요",
      text1: "이름",
      placeholder1: "이름을 입력해주세요",
      text2: "전화번호",
      placeholder2: "-없이 입력",
    },
  ];

  const checkRepeatPassword = (text) => {
    let isValid = password === text;
    isValid ? setIsRepeatPasswordValid(true) : setIsRepeatPasswordValid(false);
    repeatPasswordValidMsg
      ? setRepeatPasswrodValidMsg("비밀번호가 일치하지 않습니다.")
      : setRepeatPasswrodValidMsg("비밀번호가 일치합니다.");
  };
  const checkPassword = (text) => {
    var regExp = /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{6,15}$/;
    let isValid = regExp.test(text);
    isValid ? setIsPasswordValid(true) : setIsPasswordValid(false);
    passwordValidMsg
      ? setPasswrodValidMsg("비밀번호 형식이 맞지 않습니다.")
      : setPasswrodValidMsg("올바른 비밀번호 형식입니다.");
  };

  const checkName = (text) => {
    var regExp = /^.{1,30}$/;
    let isValid = regExp.test(text);
    isValid ? setIsNameValid(true) : setIsNameValid(false);
    nameValidMsg
      ? setPhoneValidMsg("1-30글자 사이의 이름을 입력해 주세요.")
      : setPhoneValidMsg("올바른 이름입니다.");
  };

  const checkPhone = (text) => {
    var regExp = /^\d{3}\d{3,4}\d{4}$/;
    let isValid = regExp.test(text);
    isValid ? setIsPhoneValid(true) : setIsPhoneValid(false);
    phoneValidMsg
      ? setPhoneValidMsg("잘못된 전화번호 형식입니다.")
      : setPhoneValidMsg("올바른 전화번호 형식입니다.");
  };

  const renderSliderItem = ({ item }) => {
    return item.key == "one" ? (
      <View style={styles.slide}>
        <View style={styles.titleContainer}>
          <Text style={styles.title} bold>
            {item.title}
          </Text>
          <TextInput
            style={[
              styles.inputStyle,
              { borderColor: isEmailValid ? Colors.primary : Colors.warn },
            ]}
            placeholder={item.placeholder}
            value={email}
            autoCapitalize="none"
            autoCompleteType="off"
            onChangeText={(text) => {
              setEmail(text), checkIsAllValid();
            }}
            onBlur={checkEmail}
          />
          <Text
            style={{
              fontSize: 10,
              color: isEmailValid ? Colors.primary : Colors.warn,
            }}
          >
            {emailValidMsg}
          </Text>
        </View>
      </View>
    ) : item.key == "two" ? (
      <View style={styles.slide}>
        <KeyboardAvoidingView style={styles.titleContainer}>
          <Text style={styles.title} bold>
            {item.title}
          </Text>
          <Text style={styles.label}>{item.text1}</Text>
          <TextInput
            style={[
              styles.inputStyle,
              { borderColor: isPasswordValid ? Colors.primary : Colors.warn },
            ]}
            value={password}
            onChangeText={(text) => {
              setPassword(text), checkPassword(text), checkIsAllValid();
            }}
            autoCapitalize="none"
            autoCompleteType="off"
            placeholder={item.placeholder1}
            secureTextEntry={true}
          />
          {!isPasswordValid && (
            <Text
              style={{
                fontSize: 10,
                color: isPasswordValid ? Colors.primary : Colors.warn,
              }}
            >
              {passwordValidMsg}
            </Text>
          )}
          <Text style={styles.label}>{item.text2}</Text>
          <TextInput
            value={repeatPassword}
            onChangeText={(text) => {
              setRepeatPassword(text),
                checkRepeatPassword(text),
                checkIsAllValid();
            }}
            style={[
              styles.inputStyle,
              { borderColor: isPasswordValid ? Colors.primary : Colors.warn },
            ]}
            autoCapitalize="none"
            autoCompleteType="off"
            placeholder={item.placeholder2}
            secureTextEntry={true}
          />
          {!isRepeatPasswordValid && (
            <Text
              style={{
                fontSize: 10,
                color: isRepeatPasswordValid ? Colors.primary : Colors.warn,
              }}
            >
              {repeatPasswordValidMsg}
            </Text>
          )}
        </KeyboardAvoidingView>
      </View>
    ) : (
      <View style={styles.slide}>
        <View style={styles.titleContainer}>
          <Text style={styles.title} bold>
            {item.title}
          </Text>
          <Text style={[styles.label, { alignSelf: "center" }]}>
            {item.text0}
          </Text>
          <TouchableOpacity
            style={{
              alignSelf: "center",
              justifyContent: "center",
              width: 72,
              height: 72,
              borderRadius: 36,
              backgroundColor: Colors.gray,
            }}
            onPress={pickImage}
          >
            {profileImage ? (
              <Image
                source={{ uri: profileImage.uri }}
                style={{ width: 72, height: 72, borderRadius: 36 }}
              />
            ) : (
              <Image
                source={icons.uploadProfile}
                style={{ width: 48, height: 48, alignSelf: "center" }}
              />
            )}
          </TouchableOpacity>
          <Text style={styles.label}>{item.text1}</Text>
          <TextInput
            style={[
              styles.inputStyle,
              { borderColor: isNameValid ? Colors.primary : Colors.warn },
            ]}
            value={name}
            onChangeText={(text) => {
              setName(text), checkName(text), checkIsAllValid();
            }}
            placeholder={item.placeholder1}
          />
          {!isNameValid && (
            <Text
              style={{
                fontSize: 10,
                color: isNameValid ? Colors.primary : Colors.warn,
              }}
            >
              {nameValidMsg}
            </Text>
          )}
          <Text style={styles.label}>{item.text2}</Text>
          <TextInput
            value={phoneNumber}
            keyboardType="numeric"
            onChangeText={(text) => {
              setPhoneNumber(text), checkPhone(text), checkIsAllValid();
            }}
            style={[
              styles.inputStyle,
              { borderColor: isPhoneValid ? Colors.primary : Colors.warn },
            ]}
            placeholder={item.placeholder2}
          />
          {!isPhoneValid && (
            <Text
              style={{
                fontSize: 10,
                color: isPhoneValid ? Colors.primary : Colors.warn,
              }}
            >
              {phoneValidMsg}
            </Text>
          )}
        </View>
      </View>
    );
  };

  const onDone = () => {
    const showToast = (text) => {
      return Toast.show({
        type: "error",
        text1: text,
        visibilityTime: 1000,
        autoHide: true,
        bottomOffset: 40,
        position: "top",
      });
    };

    if (!isEmailValid) {
      showToast("이메일을 확인해주세요.");
    } else if (!isRepeatPasswordValid) {
      showToast("비밀번호를 확인해주세요.");
    } else if (!isNameValid) {
      showToast("이름을 확인해주세요.");
    } else if (!isPhoneValid) {
      showToast("전화번를 확인해주세요.");
    } else {
      isAllValid
        ? handleSignup({
            name: name,
            email: email,
            password: password,
            phone_number: phoneNumber,
            image_url: profileImageUrl,
          })
        : Toast.show({
            type: "error",
            text1: "아직 입력되지 않은 정보가 있습니다!",
            visibilityTime: 1000,
            autoHide: true,
            bottomOffset: 40,
            position: "top",
          });
    }

    
  };

  const renderNextButton = () => {
    return (
      <View style={styles.sliderButton}>
        <Text style={styles.sliderButtonTxt} bold>
          다음
        </Text>
      </View>
    );
  };
  const renderDoneButton = () => {
    return (
      <View style={styles.sliderButton}>
        <Text style={styles.sliderButtonTxt} bold>
          회원가입 완료
        </Text>
      </View>
    );
  };

  return (
    <Layout navigation={navigation} withBack title="회원가입" size={24}>
      <AppIntroSlider
        dotStyle={{
          backgroundColor: Colors.white,
          borderColor: Colors.primary,
          borderWidth: 1,
        }}
        activeDotStyle={{ backgroundColor: Colors.primary }}
        bottomButton
        renderItem={renderSliderItem}
        data={slides}
        renderDoneButton={renderDoneButton}
        renderNextButton={renderNextButton}
        onDone={onDone}
      />
    </Layout>
  );
}
const styles = StyleSheet.create({
  slide: {
    flex: 1,
    resizeMode: "cover",
    maxHeight: 715,
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  titleContainer: {
    width: 335,
    paddingBottom: 100,
  },
  inputStyle: {
    padding: 10,
    backgroundColor: Colors.white,
    borderColor: Colors.gray,
    borderWidth: 1,
    borderRadius: 4,
    width: 335,
    height: 40,
  },
  content: {
    top: 130,
    fontSize: 14,
    color: Colors.gray,
    alignSelf: "flex-start",
  },
  title: {
    top: 10,
    fontSize: 20,
    color: Colors.black,
    alignSelf: "flex-start",
  },
  image: {
    top: 47,
    width: 277,
    height: 567,
  },
  sliderButton: {
    bottom: 10,
    width: 337,
    height: 52,
    backgroundColor: Colors.primary,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sliderButtonTxt: {
    color: Colors.white,
    alignSelf: "center",
  },
  label: {
    marginTop: 30,
    alignSelf: "flex-start",
    fontSize: 14,
    color: Colors.gray,
  },
});
