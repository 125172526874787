import React, { createContext, useState, useEffect } from "react";
import { getToken , saveToken } from "../components/utils/Token";
import env from "../constants/env";
import axios from "axios";
const AuthContext = createContext();

const AuthProvider = (props) => {
  // user null = loading
  const [user, setUser] = useState({ auth: null });
  const [userInfo, setUserInfo] = useState(null);
  useEffect(() => {
    checkLogin();
  }, []);
  async function checkLogin() {
    console.log("Check Login!");
    try {
      axios.get(`https://${env.apiUrl}/api/v1/user/profile`, {   
      proxy: {
          host: `https://${env.apiUrl}`,
          port: 443
      },
      headers:{
        Authorization: await getToken(),
      }
    })

      // fetch(`https://${env.apiUrl}/api/v1/user/profile`, {
      //   method: "GET",
      //   mode: "cors",
      //   withCredentials: true,
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Headers":
      //       "Origin, X-Requested-With, Content-Type, Accept",
      //     "Access-Control-Allow-Methods":"GET,PUT,POST,DELETE,OPTIONS",
      //     "Access-Control-Allow-Credentials":'true',
      //     Authorization: await getToken(),
      //   },
      // })
    .then((response) => {
      console.log(response.data.data);
			setUserInfo(response.data.data);
		    setUser((user) => ({
				auth: true,
			  }));
			})
		.catch((error) => {console.log(error);
			setUserInfo(null);
			setUser((user) => ({
				auth: false,
			  }));

		});
    } catch {
      (e) => console.log(e);
    }
  }


  // Login updates the user data with a name parameter
  const login = () => {
    setUser((user) => ({
      auth: true,
    }));
  };

  // Logout updates the user data to default
  const logout = () => {
	console.log('logged out');
	saveToken('');
    setUser((user) => ({
      auth: false,
	}));
	console.log("user.auth", user.auth);
  };


  return (

    <AuthContext.Provider
      value={{
		user, login, logout, userInfo
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
