import React, { Component } from 'react';
import { View, TouchableOpacity } from 'react-native';
import { ScaledSheet } from 'react-native-size-matters';
import Colors from '../../constants/colors';
import Text from '../utils/NotoFont';

const SectionBar = (props) =>{
    return (
        <View style={styles.container}>
        <Text style={styles.title} bold>{props.title}</Text>
        <TouchableOpacity 
        onPress={props.buttonOnPress}
        style={styles.button}>
            <Text style={styles.buttonTxt}>{props.buttonName}</Text>
        </TouchableOpacity>
      </View>
    )
}

const styles = ScaledSheet.create({
    container:{
        flex:1,
        flexDirection:"row",
        alignItems:"center",
        justifyContent:'space-between',
        width:335,
        minHeight:56,
        maxHeight:56,
    },
    title:{
        fontSize:16,
        color:Colors.lightBlack,
    },
    button:{
        alignContent:'flex-end',
        minWidth:47,
        maxWidth:58,
        height:16
    },
    buttonTxt:{
        fontSize:12,
        color:Colors.lightBlack,
        textDecorationLine: 'underline'
    },
});
export default SectionBar;