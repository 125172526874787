import React, { useState, useEffect, useContext, useRef } from "react";
import { Image, Platform, StyleSheet, View ,TouchableOpacity} from "react-native";
import { NavigationContainer } from "@react-navigation/native";
import { createStackNavigator } from "@react-navigation/stack";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import Text from '../components/utils/NotoFont';

import { AuthContext } from "../provider/AuthProvider";
import TabBarText from "../components/utils/TabBarText";
import Colors from "../constants/colors";

// Main
import Home from "../screens/Home";
import Login from "../screens/Login";
import Register from "../screens/Register";
import Upload from "../screens/solar";
import Community from "../screens/Community";
import CommunityDetail from "../screens/CommunityDetail";
import CheetingDetail from "../screens/CheetingDetail";
import Profile from "../screens/Profile";
import Loading from "../screens/utils/Loading";
import CreateCheeting from "../screens/CreateCheeting";
import CreateCommunity from "../screens/CreateCommunity";
import icons from "../constants/icons";
import Modal from "react-native-modalbox";
import {Divider} from "../components/global/ListItem";
const AuthStack = createStackNavigator();
const navigationRef = React.createRef();

function navigate(name, params) {
  navigationRef.current && navigationRef.current.navigate(name, params);
}

const Auth = () => {
  return (
    <AuthStack.Navigator
      screenOptions={{
        headerMode: "none",
        headerShown: false,
      }}
    >
      <AuthStack.Screen name="Login" component={Login} />
      <AuthStack.Screen name="Register" component={Register} />
    </AuthStack.Navigator>
  );
};


const MainStack = createStackNavigator();
const Main = () => {
  return (
    <MainStack.Navigator
      screenOptions={{
        headerMode: "none",
        headerShown: false,
      }}
    >
      <MainStack.Screen name="main" component={main} />
      <MainStack.Screen name="Cheeting" component={Home} />
      <MainStack.Screen name="CheetingDetail" component={CheetingDetail} />
      <MainStack.Screen name="CreateCheeting" component={CreateCheeting} />
      <MainStack.Screen name="CreateCommunity" component={CreateCommunity} />
      <MainStack.Screen name="Upload" component={Upload} />
      <MainStack.Screen name="CommunityDetail" component={CommunityDetail} />
      <MainStack.Screen name="Profile" component={Profile} />
    </MainStack.Navigator>
  );
};

const Tabs = createBottomTabNavigator();

const main = () => {
  return (
    <Tabs.Navigator
      initialRouteName={"Home"}
      tabBarOptions={{
        tabStyle: { borderTopWidth: 0, color: Colors.skyblue },
        style: {
          paddingTop: 4,
          paddingBottom: 11,
          backgroundColor: Colors.background,
          borderTopWidth: 0,
          height: 60,
          shadowColor: Colors.white,
          shadowOpacity: 0.1,
          shadowRadius: 20,
          shadowOffset: {
            height: -10,
            width: 1,
          },
        },
        activeTintColor: Colors.skyblue,
      }}
    >
      {/* these icons using Ionicons */}
      <Tabs.Screen
        name="Home"
        component={Home}
        options={{
          tabBarLabel: ({ focused }) => (
            <TabBarText
              style={{ marginBottom: 5 }}
              focused={focused}
              title="지역모임"
            />
          ),
          tabBarIcon: ({ focused }) => {
            return (
              <Image
                resizeMode={"contain"}
                source={icons.location}
                style={[
                  styles.tabIcon,
                  focused ? styles.activeTintColor : styles.inActiveTintColor,
                ]}
              />
            );
          },
        }}
      />

      <Tabs.Screen
        name="Upload"
        component={Upload}
        listeners={({ navigation }) => ({
          tabPress: (e) => {
            e.preventDefault();
            modalRef.current.open();
            console.log("Open Drawer here!");
            // navigation.dispatch(DrawerActions.openDrawer())
          },
        })}
        options={{
          tabBarLabel: ({ focused }) => (
            <></>
            // <TabBarText focused={focused} title="" />
          ),
          tabBarIcon: ({ focused }) => {
            return (
              <Image
                resizeMode={"contain"}
                source={icons.plus}
                style={{ ...styles.tabIcon, width: 24, height: 24 }}
              />
            );
          },
        }}
      />
      <Tabs.Screen
        name="Community"
        component={Community}
        options={{
          tabBarLabel: ({ focused }) => (
            <TabBarText focused={focused} title="커뮤니티" />
          ),
          tabBarIcon: ({ focused }) => {
            return (
              <Image
                resizeMode={"contain"}
                source={icons.community}
                style={[
                  styles.tabIcon,
                  focused ? styles.activeTintColor : styles.inActiveTintColor,
                ]}
              />
            );
          },
        }}
      />
    </Tabs.Navigator>
  );
};

const linking = {
  prefixes: ["https://thecheers.app", "thecheers://"],
  config: {
    screens: {
      main: {
        path: "",
        screens: {
          Cheeting: "/cheeting",
          Community: "/community",
          Profile: "/profile",
          // CreateCheeting:"/createCheeting",
          // CreateCommunity:"/createCommunity"
        },
      },
      Cheeting: {
        path: "/cheeting/:id",
        parse: {
          cheeting_id: (id) => `${decodeURIComponent(id)}`,
        },
      },
      Community: {
        path: "/community/:id",
        parse: {
          community_id: (id) => `${decodeURIComponent(id)}`,
        },
      },
      CreateCommunity: {
        path: "/createCommunity/:id",
        parse: {
          community_id: (id) => `${decodeURIComponent(id)}`,
        },
      },
      CreateCheeting: {
        path: "/createCheeting/:id",
        parse: {
          cheeting_id: (id) => `${decodeURIComponent(id)}`,
        },
      },
    },
  },
};

const BottomModal = React.forwardRef((props, ref) => {
  return (
    <Modal
      position={"bottom"}
      style={{
        backgroundColor: Colors.white,
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: 106,
      }}
      ref={ref}
      swipeToClose={true}
      // onClosed={onClose}
      // onOpened={onOpen}
    >
      <View
        style={{
          borderRadius: 4,
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <TouchableOpacity
          style={{
            width: 150,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => {
            navigationRef.current.navigate("CreateCheeting", {
              cheeting_id: "new",
            });
            modalRef.current.close();
          }}
        >
          <Image style={{ width: 36, height: 36 }} source={icons.map} />
          <Text style={{ fontSize: 14, color: Colors.B_02, marginTop: 3 }}>
            {"치팅 오픈"}
          </Text>
        </TouchableOpacity>
        <Divider vertical height={50} />
        <TouchableOpacity
          style={{
            width: 150,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          onPress={() => {
            navigationRef.current.navigate("CreateCommunity", {
              community_id: "new",
            });
            modalRef.current.close();
          }}
        >
          <Image style={{ width: 36, height: 36 }} source={icons.feed} />
          <Text style={{ fontSize: 14, color: Colors.B_02, marginTop: 3 }}>
            {"피드 쓰기"}
          </Text>
        </TouchableOpacity>
      </View>
    </Modal>
  );
});

const RootStack = createStackNavigator();

const modalRef = React.createRef();

export default () => {
  const [isLoading, setIsLoading] = useState(true);
  const { user } = useContext(AuthContext);
  const drawerRef = useRef();

  setTimeout(() => {
    setIsLoading(false);
  }, 1000);

  return (
    <NavigationContainer linking={linking} ref={navigationRef}>
      <BottomModal ref={modalRef} />
      {/* {isLoading == true && <Loading />} */}
      {isLoading == false && (user.auth ? <Main /> : <Auth />)}
      {/* {<Root/>} */}
      {/* {<Main />} */}
    </NavigationContainer>
  );
};

const styles = StyleSheet.create({
  tabIcon: {
    width: 20,
    height: 20,
    ...Platform.select({
      ios: {
        top: 24,
      },
      android: {},
      default: {
        // other platforms, web for example
      },
    }),
  },
  activeTintColor: {
    tintColor: Colors.ActiveTab,
  },
  inActiveTintColor: {
    tintColor: Colors.inActiveTab,
  },
});
