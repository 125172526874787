import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect,useContext,useCallback } from "react";
import {
  StyleSheet,
  // TouchableOpacity,
  View,
  ScrollView,
  // FlatList,
  Dimensions,
} from "react-native";
import * as firebase from "firebase";

import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import SegmentedControlTab from "react-native-segmented-control-tab";
import { FeedItem } from "../components/global/ListItem";
import Text from '../components/utils/NotoFont';
import env from "../constants/env";
import {getToken} from "../components/utils/Token";
import { AuthContext } from "../provider/AuthProvider";
import { RefreshControl } from 'react-native-web-refresh-control';
import {handleLike} from '../components/utils/communityFunctions';
import icons from "../constants/icons";

export default function ({ navigation }) {

  const [feeds, setFeeds] = useState([]);
  const [refreshing, setRefreshing] = useState(false)
  const { userInfo } = useContext(AuthContext);

  const reloadList = useCallback(() => {
    setRefreshing(true)

    getCommunityList().then(() => {
      setRefreshing(false)
    })
  }, [])

  const updateLike = (id) => {
    const newList = feeds.map((item) => {
      if (item.community_id === id) {
        const updatedItem = {
          ...item,
          whether_liked: !item.whether_liked,
          like_cnt: item.whether_liked ? item.like_cnt-1 : item.like_cnt+1,
        };
 
        return updatedItem;
      }
 
      return item;
    });
 
    setFeeds(newList);
  }

  useEffect(() => {
    (async () => {
      reloadList();
      // await getCommunityList();

  })();
},[reloadList] )
  async function getCommunityList(){
    fetch(`https://${env.apiUrl}/api/v1/community`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
      }
    })
      .then(res => res.json())
      .then(json=> {
        console.log(json.data);
        setFeeds(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get(
    "window"
  );

  const [tabIndex, setTabIndex] = useState(0);

  function handleTabIndexSelect(index) {
    setTabIndex(index);
  }

  const feedSamples = [
    {
      id: 1,
      name: "박상욱",
      profile: require("../assets/profile6.png"),
      tags: ["#와인한잔", "#멋진",],
      date: "2021-10-24T12:59-0500",
      likes:314,
      isLiked:true,
      content:"와인과 함께 떠나는 즐거운 여행, 새로운 곳에서 만난 사람들과 수많은 와인의 세계에 또한번 감탄하게 되는 중요한 순간이었다.",
      images: [
        {
          image:
            'https://t1.daumcdn.net/cfile/tistory/990D894D5DE6FA671D',
          desc: 'Silent Waters in the mountains in midst of Himilayas',
        },
        {
          image:
            'https://img1.daumcdn.net/thumb/R720x0.q80/?scode=mtistory2&fname=http%3A%2F%2Fcfile30.uf.tistory.com%2Fimage%2F2604B74457F915CC183503',
          desc:
            'Red fort in India New Delhi is a magnificient masterpeiece of humans',
        },
        {
          image:
            'https://t1.daumcdn.net/cfile/tistory/237725365834708F10',
          desc:
            'Sample Description below the image for representation purpose only',
        },
      ],
      comments: 1123322,
      shares: 1333,
    },

  ];

  const FeedList = () => {
    return (
      <View style={{ flex: 1, flexDirection: "column" }}>
        {feeds.map((item) => (
          <FeedItem
          onPress={() =>
            navigation.navigate('CommunityDetail', {
              community_id: item.community_id,
            })
          }
            key={item.community_id}
            profile={item.user.image_url}
            name={item.user.name}
            tags={item.hashtags}
            date={item.created_at}
            likes={item.like_cnt}
            isLiked={item.whether_liked}
            content={item.content}
            thumbnailImage={item.thumbnail_image_url}
            comments={item.reply_cnt}
            shares={item.shares}
            onPressLike={()=>{updateLike(item.community_id),handleLike({"community_id":item.community_id})}}
            />
        ))}
      </View>
    );
  };


  const Notice = (props) => {
    return(
    <View style={{top:12,width:343,height:72, backgroundColor:Colors.darkgray, borderRadius:4, alignItems: "center",justifyContent:"center"}}>
      <Text numberOfLines={2} style={{ fontSize: 14, width:323, height:42, color: Colors.white,  }}>{props.notice}</Text>
    </View>)
  }

  return (
    <Layout
      navigation={navigation}
      withLogo
      size={24}
      title="내 작업실"
      buttonA
      imageA={require("../assets/icon_search.png")}
      onPressA={() => {
        console.log("A");
      }}
      icon={"ios-menu"}
      buttonB
      imageB={icons.profile}
      onPressB={() => {
        navigation.navigate('Profile')      
      }}
    >
      <View style={{ flex: 1 }}>
      <View
            style={{
              flex: 4,
              backgroundColor: Colors.backgroundGray,
              alignItems: "center",
            }}
          >
            <ScrollView style={styles.container}
               refreshControl={
                <RefreshControl refreshing={refreshing} onRefresh={reloadList} />
             }
          >
              <FeedList />
            </ScrollView>
          </View>
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: Colors.backgroundGray,
    top: 25,
    marginBottom: 25,
  },
  horizontalContainer: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  cardContainer: {
    top: 100,
    width: "98%",
    left: "1%",
  },
  cardList: {
    margin: 15,
    width: "100%",
  },
  userInfo: {
    top: 50,
    left: 50,
    flex: 1,
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: 50,
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: 30,
    fontWeight: "bold",
    fontSize: 30,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: 20,
    fontWeight: "bold",
  },
  sectionBar: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    minWidth: 372,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderColor: "#DDDDDD",
    minWidth: "100%",
    alignItems: "center",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
});
