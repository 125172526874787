import Constants from 'expo-constants';
import { Platform } from "react-native";

const localhost =
 Platform.OS === "ios" ? "localhost:8000" : "127.0.0.1:8000";
const prodUrl = "backend.thecheers.app";
const mApiKey = "AIzaSyA2D4klugpmm2pz1Md6CzRY4ZDUHdYdAyc";

const ENV = {
    dev: {
      apiUrl: prodUrl,
      mApiKey: mApiKey
    },
    prod: {
      apiUrl: prodUrl,
      mApiKey: mApiKey
      // Add other keys you want here
    }
};

const getEnvVars = (env = Constants.manifest.releaseChannel) => {
  if (env === null || env === undefined || env === "" || env.indexOf("dev") !== -1) return ENV.dev;
  if (env.indexOf("prod") !== -1) return ENV.prod;
}

const selectedENV = getEnvVars();

export default selectedENV;
