import { StatusBar } from "expo-status-bar";
import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  StyleSheet,
  // TouchableOpacity,
  View,
  ScrollView,
  // FlatList,
  Dimensions,
} from "react-native";

import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import SegmentedControlTab from "react-native-segmented-control-tab";
import SectionBar from "../components/global/SectionBar";
import { MeetingListItem } from "../components/global/ListItem";
import { getToken } from "../components/utils/Token";
import { AuthContext } from "../provider/AuthProvider";
import env from "../constants/env";
import { RefreshControl } from "react-native-web-refresh-control";
import * as Location from "expo-location";
import icons from "../constants/icons";
import Text from "../components/utils/NotoFont";
export default function ({ navigation }) {
  const [cheetings, setCheetings] = useState([]);
  const [myCheetings, setMyCheetings] = useState([]);
  const [myCandidateCheetings, setMyCandidateCheetings] = useState([]);
  const [myLikedCheetings, setMyLikedCheetings] = useState([]);
  const { userInfo } = useContext(AuthContext);
  const [refreshing, setRefreshing] = useState(false);
  const [myRefreshing, setMyRefreshing] = useState(false);
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } =
    Dimensions.get("window");
  const reloadList = useCallback(() => {
    setRefreshing(true);

    getCheetings().then(() => {
      setRefreshing(false);
    });
  }, []);

  const reloadMyList = useCallback(() => {
    setMyRefreshing(true);
    userInfo &&
      getMyCheetings().then(() => {
        setMyRefreshing(false);
      }),
      getMyCandidateCheetings().then(() => {
        setMyRefreshing(false);
      }),
      getMyLikedCheetings().then(() => {
        setMyRefreshing(false);
      });
  }, []);

  useEffect(() => {
    (async () => {
      reloadList();
      reloadMyList();
      // await getCommunityList();
    })();
  }, [reloadList]);
  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [address, setAddress] = useState(null);
  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setErrorMsg("Permission to access location was denied");
        return;
      }

      let location = await Location.getCurrentPositionAsync({});
      setLocation(location);
      fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          location.coords.latitude +
          "," +
          location.coords.longitude +
          "&key=" +
          env.mApiKey +
          "&language=ko"
      )
        .then((response) => response.json())
        .then((responseJson) => {
          setAddress(responseJson.results[0].address_components[3].long_name);
        })
        .catch((err) => console.log("udonPeople error : " + err));
      // await getCurrentLocation();
    })();
  }, []);

  async function getCheetings() {
    fetch(`https://${env.apiUrl}/api/v1/cheeting`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setCheetings(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getMyCheetings() {
    fetch(`https://${env.apiUrl}/api/v1/cheeting/user/${userInfo.user_id}`, {
      method: "GET",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setMyCheetings(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getMyCandidateCheetings() {
    fetch(
      `https://${env.apiUrl}/api/v1/cheeting/user/${userInfo.user_id}?condition=candidate`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: await getToken(),
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setMyCandidateCheetings(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getMyLikedCheetings() {
    fetch(
      `https://${env.apiUrl}/api/v1/cheeting/user/${userInfo.user_id}?condition=liked`,
      {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: await getToken(),
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        setMyLikedCheetings(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const [tabIndex, setTabIndex] = useState(0);

  function handleTabIndexSelect(index) {
    setTabIndex(index);
  }

  const MeetingList = () => {
    return (
      <View style={{  flexDirection: "column" }}>
        {cheetings.map((item) => (
          <MeetingListItem
            onPress={() =>
              navigation.navigate("CheetingDetail", {
                cheeting_id: item.cheeting_id,
              })
            }
            key={item.cheeting_id}
            title={(item.isAD ? "[AD] " : "") + item.title}
            labelTxt={
              item.people_limit - item.joinedMembers > 0
                ? item.people_limit - item.joinedMembers + "자리 남음"
                : ""
            }
            image={item.user.image_url}
            cheerLeaderName={item.user.name}
            review={item.review}
            totalMembers={item.people_limit}
            joinedMembers={item.attendees.length}
            tags={item.hashtags}
            location={item.county}
            date={item.start_at}
            price={item.participation_fee}
            soldout={item.soldout}
            type="A"
          />
        ))}
      </View>
    );
  };

  const MyCheetingList = (props) => {
    return (
      <View style={{ flexDirection: "column" }}>
        {props.cheeting.map((item) => (
          <MeetingListItem
            onPress={() =>
              navigation.navigate("CheetingDetail", {
                cheeting_id: item.cheeting_id,
              })
            }
            key={item.cheeting_id}
            title={(item.isAD ? "[AD] " : "") + item.title}
            labelTxt={
              item.mine
                ? "모임주최"
                : item.status == "PENDING"
                ? "참석대기"
                : item.status == "Liked"
                ? "찜한모임"
                : item.status == "APPROVED"
                ? "참석확정"
                : "참석불발"
            }
            image={item.user.image_url}
            cheerLeaderName={item.user.name}
            review={item.review}
            totalMembers={item.people_limit}
            joinedMembers={item.attendees.length}
            tags={item.hashtags}
            location={item.county}
            date={item.start_at}
            price={item.participation_fee}
            soldout={item.soldout}
            type="A"
            my={true}
            reply_cnt={item.reply_cnt}
            whether_liked={item.whether_liked}
          />
        ))}
      </View>
    );
  };

  return (
    <Layout
      navigation={navigation}
      withLogo
      title="내 작업실"
      size={24}
      buttonB
      imageB={icons.profile}
      onPressB={() => {
        navigation.navigate("Profile");
      }}
    >
      <View style={{ flex: 1 }}>
        <SegmentedControlTab
          values={["치팅 모집", "MY 치팅"]}
          selectedIndex={tabIndex}
          onTabPress={handleTabIndexSelect}
          borderRadius={0}
          tabsContainerStyle={{
            height: 56,
            backgroundColor: Colors.white,
            borderColor: Colors.white,
          }}
          tabStyle={{
            backgroundColor: Colors.white,
            borderWidth: 0,
            borderColor: Colors.white,
          }}
          firstTabStyle={{
            borderWidth: 0,
            borderColor: Colors.white,
            borderBottomColor: Colors.black,
          }}
          activeTabStyle={{
            backgroundColor: Colors.white,
            borderWidth: 0,
            borderBottomWidth: 2,
            borderColor: Colors.black,
          }}
          tabTextStyle={{
            color: Colors.lightGray,
            fontFamily: "NotoB",
            fontSize: 16,
          }}
          activeTabTextStyle={{
            color: Colors.black,
            fontFamily: "NotoB",
            fontSize: 16,
          }}
        />
        {tabIndex === 0 && (
          <View
            style={{
              flex: 4,
              backgroundColor: Colors.backgroundGray,
              alignItems: "center",
            }}
          >
            <View style={styles.sectionDivider}>
              <SectionBar
                title={
                  address ? address + "에 계시네요 :)" : "현재 위치 가져오는 중"
                }
                // buttonName={"지역 변경"}
                // buttonOnPress={() => {
                //   console.log("지역변경");
                // }}
              />
            </View>
            <ScrollView
              style={styles.container}
              refreshControl={
                <RefreshControl
                  refreshing={refreshing}
                  onRefresh={reloadList}
                  
                />
              }
            >
              <MeetingList />
            </ScrollView>
          </View>
        )}
        {tabIndex === 1 && (
          <View
            style={{
              flex: 4,
              backgroundColor: Colors.backgroundGray,
              alignItems: "center",
            }}
          >
            <ScrollView
              style={styles.container}
              refreshControl={
                <RefreshControl
                  refreshing={myRefreshing}
                  onRefresh={reloadMyList}
                />
              }
            >
              <View style={styles.sectionDivider}>
                <SectionBar
                  style={styles.sectionBar}
                  title={"내가 주최중인 치팅"}
                />
              </View>
              {myCheetings.length > 0 ? (
                <MyCheetingList cheeting={myCheetings} />
              ) : (
                <Text style={styles.sectionNone}>
                  {"아직 주최중인 치팅이 없습니다."}
                </Text>
              )}
              <View style={styles.sectionDivider}>
                <SectionBar
                  style={styles.sectionBar}
                  title={"내가 참가중인 치팅"}
                />
              </View>
              {myCandidateCheetings.length > 0 ? (
                <MyCheetingList cheeting={myCandidateCheetings} />
              ) : (
                <Text style={styles.sectionNone}>
                  {"아직 참가중인 치팅이 없습니다."}
                </Text>
              )}
              <View style={styles.sectionDivider}>
                <SectionBar
                  style={styles.sectionBar}
                  title={"내가 찜한 치팅"}
                />
              </View>
              {myLikedCheetings.length > 0 ? (
                <MyCheetingList cheeting={myLikedCheetings} />
              ) : (
                <Text style={styles.sectionNone}>
                  {"아직 찜한 치팅이 없습니다."}
                </Text>
              )}
            </ScrollView>
          </View>
        )}
      </View>
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: Colors.backgroundGray,
    top: 25,
    marginBottom: 25,
  },
  horizontalContainer: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  cardContainer: {
    top: 100,
    width: "98%",
    left: "1%",
  },
  cardList: {
    margin: 15,
    width: "100%",
  },
  userInfo: {
    top: 50,
    left: 50,
    flex: 1,
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: 50,
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: 30,
    fontWeight: "bold",
    fontSize: 30,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: 20,
    fontWeight: "bold",
  },
  sectionBar: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    minWidth: 372,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderColor: "#DDDDDD",
    minWidth: "100%",
    alignItems: "center",
  },
  sectionNone: {
    fontSize:16,
    color:Colors.lightBlack,
    marginLeft: 15
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
});
