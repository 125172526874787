import { StatusBar } from "expo-status-bar";
import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  StyleSheet,
  TouchableOpacity,
  KeyboardAvoidingView,
  View,
  ScrollView,
  // FlatList,
  Image,
  Dimensions,
  ImageBackground,
  Platform,
} from "react-native";
import icons from "../constants/icons";
import * as firebase from "firebase";
import { Rating } from "react-native-ratings";
import { DatePickerModal, TimePickerModal } from "react-native-paper-dates";
import * as Location from "expo-location";
import MapView, { Marker } from "react-native-maps";
import strftime from "strftime";
import Moment from "react-moment";
import * as ImagePicker from "expo-image-picker";

import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import { Tags } from "../components/global/ListItem";
import Text from "../components/utils/NotoFont";
import Modal from "react-native-modalbox";
import { WebView } from "react-native-webview";
import { AuthContext } from "../provider/AuthProvider";
import Toast from "react-native-toast-message";
import env from "../constants/env";
import { getToken } from "../components/utils/Token";
import { RefreshControl } from "react-native-web-refresh-control";
import {
  TextInput,
  Button,
  DefaultTheme,
  Provider as PaperProvider,
} from "react-native-paper";
import colors from "../constants/colors";
import KoreaAddress from "../assets/korea.json";
import DropDownPicker from "react-native-dropdown-picker";
import { uploadSingleImage } from "../components/utils/UploadImage";

export default function ({ route, navigation }) {
  const { cheeting_id = null } = route.params ?? {};
  const { userInfo } = useContext(AuthContext);
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } =
    Dimensions.get("window");
  const screen = Dimensions.get("window");
  const ASPECT_RATIO = screen.width / screen.height;

  const LATITUDE_DELTA = 0.03;
  const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
  const [currentRegion, setCurrentRegion] = useState(null);
  const [date, setDate] = useState(null);
  const dateRef = useRef(null);
  const [open, setOpen] = useState(false);

  const numberFormat = (value) =>
    new Intl.NumberFormat("ko-KR", {
      currency: "KRW",
    }).format(value);

  const wineModalRef = useRef();
  const addressModalRef = useRef();

  const [message, setMessage] = useState("");
  const [coverImage, setCoverImage] = useState();
  const [currentWine, setCurrentWine] = useState(0);
  const [title, setTitle] = useState("");
  const [newWineData, setNewWineData] = useState([
    { name: "", grape_variety: "", vintage: 2021, image_urls: [] },
  ]);
  const [tag, setTag] = useState("");
  const [hashtags, setHashtags] = useState([]);
  const [hashtagText, setHashtagText] = useState("");
  const [introduction, setIntroduction] = useState();
  const [people_limit, setPeopleLimit] = useState();
  const [city, setCity] = useState("");
  const [county, setCounty] = useState("");
  const [place_detail, setPlaceDetail] = useState("");
  const [place_name, setPlaceName] = useState("");
  const [start_at, setStartAt] = useState("");
  const [participation_fee, setParticipationFee] = useState();
  const [open_chat_link, setOpenChatLink] = useState();

  function get_date_str(date) {
    var sYear = date.getFullYear();
    var sMonth = date.getMonth() + 1;
    var sDate = date.getDate();
    var sHour = date.getHours();
    var sMin = date.getMinutes();
    var sSec = date.getSeconds();

    sMonth = sMonth > 9 ? sMonth : "0" + sMonth;
    sDate = sDate > 9 ? sDate : "0" + sDate;
    sHour = sHour > 9 ? sHour : "0" + sHour;
    sMin = sMin > 9 ? sMin : "0" + sMin;
    sSec = sSec > 9 ? sSec : "0" + sSec;
    return (
      sYear + "-" + sMonth + "-" + sDate + " " + sHour + ":" + sMin + ":" + sSec
    );
  }

  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [address, setAddress] = useState(null);

  async function getCheetingDetail() {
    await fetch(`https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        setTitle(json.data.title);
        setCoverImage(json.data.image_url);
        console.log(json.data.image_url);
        setStartAt(new Date(Date.parse(json.data.start_at)));
        setDate(new Date(Date.parse(json.data.start_at)));
        console.log(json.data.start_at);
        setPeopleLimit(json.data.people_limit);
        setParticipationFee(json.data.participation_fee);
        setNewWineData(json.data.wines);
        setCounty(json.data.county);
        setCity(json.data.city);
        setHashtags(json.data.hashtags);
        setHashtagText(json.data.hashtags);
        setIntroduction(json.data.introduction);
        setPlaceName(json.data.place_name);
        setPlaceDetail(json.data.place_detail);
        setOpenChatLink(json.data.open_chat_link);
        setCurrentRegion({
          latitude: json.data.latitude,
          longitude: json.data.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    (async () => {
      console.log("GET DETAIL!");
      cheeting_id != "new" && (await getCheetingDetail());
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setErrorMsg("Permission to access location was denied");
        return;
      }

      let loc = await Location.getCurrentPositionAsync({});
      setLocation(loc);
      cheeting_id == "new" &&
        setCurrentRegion({
          latitude: loc.coords.latitude,
          longitude: loc.coords.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
      console.log(loc);
    })();
  }, []);

  async function handleUpdateCheeting(data) {
    console.log("Upload Clicked!");

    fetch(`https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        console.log(res.status);
        res.status === 202
          ? Toast.show({
              type: "success",
              text1: "치팅이 업데이트 되었습니다.",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            })
          : Toast.show({
              type: "error",
              text1: "치팅 업데이트 실패.",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            });
        navigation.navigate("CommunityDetail", {
          community_id: community_id,
        });
      })
      .catch((error) => {
        Toast.show({
          type: "success",
          text1: "치팅이 업데이트 되었습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        });
        navigation.navigate("CheetingDetail", {
          cheeting_id: cheeting_id,
        });

        console.error(error);
      });
  }
  const inputRef = useRef();
  async function handleOpenCheeting(data) {
    // await handleUploadImage();
    fetch(`https://${env.apiUrl}/api/v1/cheeting`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: await getToken(),
      },
      body: JSON.stringify(data),
    })
      .then((res) => {
        res.status;
        console.log(res.status);
        console.log(res.body);
        res.status === 201
          ? (navigation.navigate("Cheeting"),
            Toast.show({
              type: "success",
              text1: "치팅 오픈 완료!",
              visibilityTime: 1000,
              autoHide: true,
            }))
          : Toast.show({
              type: "error",
              text1: "에러발생 !",
              visibilityTime: 1000,
              autoHide: true,
            });
      })

      .catch((error) => {
        let errormessage = "네트워크 에러 발생!";
        if (data.title === "") errormessage = "제목을 입력해주세요.";
        else if (data.title.length > 32)
          errormessage = "제목 글자수는 최대 32자입니다.";
        else if (start_at === "") errormessage = "시간을 선택해주세요.";
        else if (isNaN(people_limit))
          errormessage = "참석자 수를 입력해주세요.";
        else if (people_limit < 2) errormessage = "참석자 수는 최소 2명입니다.";
        else if (newWineData[currentWine].name === "")
          errormessage = "와인 정보를 입력해주세요.";
        else if (isNaN(participation_fee))
          errormessage = "참가비를 입력해주세요.";
        else if (participation_fee > 1000000)
          errormessage = "1인당 최대 참가비는 1백만원입니다.";
        else if (county === "" && city === "" && place_name === "")
          errormessage = "모임 장소를 입력해주세요.";
        else if (county === "" || city === "" || place_name === "")
          errormessage = "모임 장소를 다시 입력해주세요.";
        else if (open_chat_link === undefined)
          errormessage = "오픈 카카오톡 링크를 입력해주세요.";
        else if (introduction === undefined)
          errormessage = "치팅 소개 내용을 입력해주세요.";

        Toast.show({
          type: "error",
          text1: errormessage,
          visibilityTime: 1000,
          autoHide: true,
        });
        console.error(error);
      });
  }

  const Profile = (props) => {
    return (
      <View>
        {props.withBadge ? (
          <View style={{ flex: 1, top: -20 }}>
            <Image
              source={require("../assets/crown.png")}
              style={{
                width: 24,
                height: 24,
                alignSelf: "flex-start",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                top: 14,
                left: -4,
                zIndex: 1,
              }}
            />
            <Image
              source={
                props.image
                  ? { uri: props.image }
                  : require("../assets/icon.png")
              }
              style={{ width: 72, height: 72, borderRadius: 36 }}
            />
          </View>
        ) : (
          <View>
            <Image
              source={
                props.image
                  ? { uri: props.image }
                  : require("../assets/icon.png")
              }
              style={{ width: 54, height: 54, borderRadius: 27 }}
            />
          </View>
        )}
      </View>
    );
  };

  const CoverImage = (props) => {
    return (
      <ImageBackground
        resizeMode="cover"
        style={{ width: 375, height: 210 }}
        source={
          props.coverImage
            ? { uri: props.coverImage }
            : require("../assets/defaultCover.jpg")
        }
      >
        <View
          style={{
            flex: 1,
            top: 128,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <ProfileContent
            cheerLeaderName={props.cheerLeaderName}
            profile={props.profile}
            review={5}
            ratings={5}
          />
        </View>
      </ImageBackground>
    );
  };

  const ProfileContent = (props) => {
    return (
      <View
        style={{
          maxHeight: 70,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Profile withBadge image={props.profile} />
        <Text
          style={{ fontSize: 14, color: Colors.black }}
          numberOfLines={1}
          bold
        >
          {props.cheerLeaderName}
        </Text>
      </View>
    );
  };

  const Title = (props) => {
    return (
      <View
        style={{
          alignSelf: "center",
          width: 320,
          marginTop: 80,
          marginBottom: 10,
          height: 84,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          numberOfLines={2}
          style={{ fontSize: 22, color: Colors.black, alignSelf: "center" }}
          bold
        >
          {props.title}
        </Text>
      </View>
    );
  };

  const SectionBar = (props) => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          marginTop: props.top,
          alignItems: "flex-start",
          width: 335,
          maxHeight: 48,
          borderBottomWidth: 1,
          borderBottomColor: Colors.lightGray,
        }}
      >
        <Text style={{ color: Colors.primary, fontSize: 14 }} bold>
          {props.title}
        </Text>
      </View>
    );
  };
  const Content = () => {
    const defaultNote =
      "와인모임(치팅)의 주최자(치어리더)로서 참가자에게 안내 하고 싶은 내용을 적는 공간입니다. 일반적으로 많이 쓰이는 내용은 아래와 같습니다. 양식은 자유이나, 필요하시면 참고해주세요 :)\n\n- 참가자들에게 건네는 간단한 인삿말\n- 어떤 와인 모임인지 소개하는 말\n- 모임의 규칙 등\n- 기타 안내사항\n참석 확정 시, 이 글의 위쪽에 있는 “모임 안내”에서 카카오톡 오튼 채팅방 링크를 확인하실 수 있습니다. 모임 전까지의 소통은 오픈채팅에서 진행됩니다!";

    return (
      <View
        style={{
          flex: 2,
          marginTop: 32,
          marginBottom: 32,
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <TextInput
          // style={{ height: 503 }}
          dense
          selectionColor={Colors.primary}
          outlineColor={Colors.B_06}
          mode="outlined"
          numberOfLines={24}
          multiline
          value={introduction}
          placeholder={defaultNote}
          onFocus={() => {
            setHideOnNoteFocus(true), console.log("Hide!!");
          }}
          onBlur={() => {
            setHideOnNoteFocus(false), console.log("Show!!");
          }}
          onChangeText={(text) => setIntroduction(text)}
        />
      </View>
    );
  };

  function sendMessage() {
    console.log("Message Sent!" + message);
  }

  function onChangeText() {
    setMessage(message);
  }

  const ApplyCheeting = (props) => {
    return (
      <View
        style={{
          height: 52,
          width: 337,
          flexDirection: "row",
          alignSelf: "center",
          alignItems: "center",
          backgroundColor: props.canOpen ? Colors.primary : Colors.lightGray,
          borderRadius: 4,
        }}
      >
        <TouchableOpacity
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          onPress={props.onPress}
        >
          <Text style={{ color: Colors.white, fontSize: 18 }}>
            {props.text}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  function onOpen() {
    console.log("Modal just opened");
  }

  function onClose() {
    console.log("Modal just Closed");
  }

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: false,
      aspect: [1, 1],
      quality: 1,
      base64: true,
    });

    if (!result.cancelled) {
      let imageUrl = await uploadSingleImage(
        result,
        `https://${env.apiUrl}/api/v1/s3/upload/image`
      );
      let wine = [...newWineData];
      wine[currentWine] = {
        ...newWineData[currentWine],
        image_urls: [imageUrl],
      };
      setNewWineData(wine);
    }
  };

  const Preview = () => {
    return (
      <KeyboardAvoidingView
        style={{
          width: 295,
          height: 550,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <TouchableOpacity
          onPress={() => {
            pickImage();
          }}
        >
          <Image
            style={{ width: 295, height: 295, marginBottom: 30 }}
            source={
              newWineData[currentWine].image_urls[0]
                ? { uri: newWineData[currentWine].image_urls[0] }
                : require("../assets/wine_default.png")
            }
          />
        </TouchableOpacity>
        <View
          style={{
            width: 255,
            height: 200,
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 0,
            borderBottomColor: Colors.gray,
          }}
        >
          <TextInput
            dense
            mode="outlined"
            style={{ width: 255, height: 40, marginBottom: 10 }}
            selectionColor={Colors.primary}
            outlineColor={Colors.B_06}
            placeholder="와인명을 입력해주세요"
            value={newWineData[currentWine].name}
            onChangeText={(text) => {
              let wine = [...newWineData];
              wine[currentWine] = { ...newWineData[currentWine], name: text };
              setNewWineData(wine);
            }}
          />
          <TextInput
            dense
            mode="outlined"
            style={{ width: 255, height: 40, marginBottom: 10 }}
            selectionColor={Colors.primary}
            outlineColor={Colors.B_06}
            placeholder="포도품종을 입력하세요"
            value={newWineData[currentWine].grape_variety}
            onChangeText={(text) => {
              let wine = [...newWineData];
              wine[currentWine] = {
                ...newWineData[currentWine],
                grape_variety: text,
              };
              setNewWineData(wine);
            }}
          />
          <TextInput
            dense
            mode="outlined"
            style={{ width: 255, height: 40, marginBottom: 10 }}
            selectionColor={Colors.primary}
            outlineColor={Colors.B_06}
            placeholder="빈티지를 입력하세요"
            value={newWineData[currentWine].vintage}
            onChangeText={(text) => {
              let wine = [...newWineData];
              wine[currentWine] = {
                ...newWineData[currentWine],
                vintage: text,
              };
              setNewWineData(wine);
            }}
          />
          <View
            style={{
              width: 295,
              height: 52,
              borderTopWidth: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              style={{ marginRight: 34 }}
              onPress={() => {
                wineModalRef.current.close();
              }}
            >
              <Text style={{ color: Colors.B_04 }}>{"닫기"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginRight: 15 }}
              onPress={() => {
                wineModalRef.current.close();
              }}
            >
              <Text
                style={{
                  color:
                    newWineData[currentWine].name != "" &&
                    newWineData[currentWine].grape_variety != "" &&
                    newWineData[currentWine].vintage != ""
                      ? Colors.primary
                      : Colors.B_04,
                }}
              >
                {"저장"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </KeyboardAvoidingView>
    );
  };
  const [countyOpen, setCountyOpen] = useState(false);
  const [cityOpen, setCityOpen] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [countyList, setCountyList] = useState([]);

  useEffect(() => {
    let list = [];
    var cityKeys = Object.keys(KoreaAddress);
    cityKeys.map((item) => {
      list.push({ label: item, value: item });
    });
    setCityList(list);
  }, []);

  const AddressModal = () => {
    return (
      <Modal
        style={{
          position: "absolute",
          backgroundColor: Colors.white,
          justifyContent: "center",
          alignItems: "center",
          width: 335,
          height: 445,
        }}
        ref={addressModalRef}
        swipeToClose={false}
        onClosed={onClose}
        onOpened={onOpen}
      >
        <View
          style={{ flex: 1, flexDirection: "column", alignItems: "center" }}
        >
          <Text
            style={{ width: 295, height: 60, marginTop: 40, marginBottom: 30 }}
          >
            {"참석자들에게 안내할 와인 모임 장소를 입력해주세요"}
          </Text>
          <DropDownPicker
            open={cityOpen}
            setOpen={setCityOpen}
            onOpen={onCityOpen}
            value={city}
            items={cityList}
            containerStyle={{ width: 295, height: 40, marginBottom: 30 }}
            setValue={setCity}
            placeholder="시(도)를 선택하세요"
            onChangeValue={(value) => {
              let list = [];
              console.log(city);
              KoreaAddress[city].map((item) => {
                list.push({ label: item, value: item });
              });
              console.log(list);
              setCountyList(list);
            }}
            zIndex={1000}
          />
          <DropDownPicker
            open={countyOpen}
            setOpen={setCountyOpen}
            onOpen={onCountyOpen}
            value={county}
            containerStyle={{ width: 295, height: 40, marginBottom: 30 }}
            setValue={setCounty}
            items={countyList}
            placeholder="구(시)를 선택하세요"
            zIndex={99}
          />
          <TextInput
            dense
            mode="outlined"
            style={{ width: 295, height: 40, marginBottom: 70 }}
            selectionColor={Colors.primary}
            outlineColor={Colors.B_06}
            value={place_name}
            placeholder="상세 주소를 입력하세요"
            onChangeText={(text) => setPlaceName(text)}
            onFocus={() => {
              setHideOnNoteFocus(true), console.log("Hide!!");
            }}
            onBlur={() => {
              setHideOnNoteFocus(false), console.log("Show!!");
            }}
          />
          <View
            style={{
              width: 335,
              height: 52,
              borderTopWidth: 1,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <TouchableOpacity
              style={{ marginRight: 34 }}
              onPress={() => {
                addressModalRef.current.close();
              }}
            >
              <Text style={{ color: Colors.B_04 }}>{"닫기"}</Text>
            </TouchableOpacity>
            <TouchableOpacity
              style={{ marginRight: 15 }}
              onPress={() => {
                addressModalRef.current.close(),
                  searchPlace(city + " " + county + " " + place_name);
              }}
            >
              <Text
                style={{
                  color:
                    county != null && place_name != ""
                      ? Colors.primary
                      : Colors.B_04,
                }}
              >
                {"저장"}
              </Text>
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    );
  };

  const onCountyOpen = useCallback(() => {
    setCityOpen(false);
  }, []);

  const onCityOpen = useCallback(() => {
    setCountyOpen(false);
  }, []);

  const WineModal = (props) => {
    return (
      <Modal
        style={{
          position: "absolute",
          backgroundColor: Colors.white,
          justifyContent: "center",
          alignItems: "center",
          width: 295,
          height: 550,
        }}
        ref={wineModalRef}
        swipeToClose={false}
        onClosed={onClose}
        onOpened={onOpen}
      >
        {Preview()}
      </Modal>
    );
  };

  const onDismissSingle = React.useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const onConfirmSingle = React.useCallback(
    (params) => {
      setOpen(false);
      setVisible(true);
      dateRef.current = params.date;
    },
    [setOpen, setVisible]
  );

  const [visible, setVisible] = useState(false);
  const onDismiss = React.useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const onConfirm = React.useCallback(
    ({ hours, minutes }) => {
      setVisible(false);
      let dateDate = dateRef.current;
      console.log("dateDate", dateDate);
      dateDate.setHours(hours);
      dateDate.setMinutes(minutes);
      dateDate.setSeconds(0);
      setDate(dateDate);
      setStartAt(dateDate);
    },
    [setVisible, setDate]
  );

  const theme = {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
    },
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const [hideOnBottom, setHideOnBottom] = useState(false);
  const [hideOnNotefocus, setHideOnNoteFocus] = useState(false);

  async function searchPlace(query) {
    console.log(query);
    const proxyurl = "https://thecheers-proxy.herokuapp.com/";

    await fetch(
      proxyurl +
        "https://maps.googleapis.com/maps/api/place/findplacefromtext/json?input=" +
        query +
        "&inputtype=textquery&fields=formatted_address%2Cname%2Crating%2Copening_hours%2Cgeometry&key=" +
        env.mApiKey +
        "&language=ko"
    )
      .then((response) => response.json())
      .then((responseJson) => {
        console.log(responseJson);
        console.log(responseJson.candidates[0].formatted_address);
        console.log(responseJson.candidates[0].geometry);
        setCurrentRegion({
          latitude: responseJson.candidates[0].geometry.location.lat,
          longitude: responseJson.candidates[0].geometry.location.lng,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });

        setAddress(responseJson.candidates[0].formatted_address);
      })
      .catch((err) => console.log("error : " + err));
  }

  const pickCoverImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [16, 9],
      quality: 1,
    });

    console.log(result);

    if (!result.cancelled) {
      let imageUrl = await uploadSingleImage(
        result,
        `https://${env.apiUrl}/api/v1/s3/upload/image`
      );
      setCoverImage(imageUrl);
    }
  };

  const AddNewWine = () => {
    if (newWineData[newWineData.length - 1].name != "") {
      let array = [...newWineData];
      array.push({
        name: "",
        grape_variety: "",
        vintage: parseInt(2021),
        image_urls: [],
      });
      setNewWineData(array);
      setCurrentWine(newWineData.length);
    }
  };

  async function deleteCheeting() {
    await fetch(`https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => {
        res.status;
        console.log(res.status);
        console.log(res);
        res.status === 202
          ? (Toast.show({
              type: "success",
              text1: "치팅 삭제가 완료 되었습니다!",
              visibilityTime: 1000,
              autoHide: true,
            }),
            navigation.navigate("Cheeting"))
          : Toast.show({
              type: "error",
              text1: "에러발생 !",
              visibilityTime: 1000,
              autoHide: true,
            });
      })
      .catch((error) => {
        Toast.show({
          type: "success",
          text1: "치팅 삭제가 완료 되었습니다!",
          visibilityTime: 1000,
          autoHide: true,
        }),
          navigation.navigate("Cheeting");
        console.error(error);
      });
  }
  return (
    <Layout
      navigation={navigation}
      size={24}
      withBack
      title={userInfo ? userInfo.name + "님의 치팅" : "" + "님의 치팅"}
      buttonB
      textB={cheeting_id != "new" && "삭제하기"}
      onPressB={() => {
        deleteCheeting();
      }}
    >
      {AddressModal()}
      {WineModal()}
      <ScrollView
        style={{ flex: 1, maxHeight: SCREEN_HEIGHT - 56 }}
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            setHideOnBottom(true);
          } else {
            setHideOnBottom(false);
          }
        }}
        scrollEventThrottle={400}
      >
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <View style={{ flexWrap: "wrap", width: 375 }}>
            <TouchableOpacity
              onPress={() => {
                pickCoverImage();
              }}
            >
              <CoverImage
                cheerLeaderName={userInfo.name}
                profile={userInfo.image_url}
                level={1}
                review={5}
                ratings={5}
                coverImage={coverImage}
              />
            </TouchableOpacity>
          </View>
          <View
            style={{ marginTop: 100, flexDirection: "column", height: 150 }}
          >
            <TextInput
              value={title}
              dense
              multiline
              numberOfLines={2}
              style={{ width: 335, height: 60 }}
              selectionColor={Colors.primary}
              outlineColor={Colors.B_06}
              mode="outlined"
              placeholder="제목을 입력하세요"
              onChangeText={(text) => setTitle(text)}
              onFocus={() => {
                setHideOnNoteFocus(true), console.log("Hide!!");
              }}
              onBlur={() => {
                setHideOnNoteFocus(false), console.log("Show!!");
              }}
            />
            <Tags top={0} bottom={10} tags={hashtags} />

            <TextInput
              key={"tag"}
              dense
              multiline
              numberOfLines={2}
              style={{ width: 335, height: 44 }}
              selectionColor={Colors.primary}
              outlineColor={Colors.B_06}
              mode="outlined"
              value={hashtagText}
              onChangeText={(text) => {
                setHashtagText(text), setHashtags(text.split(","));
              }}
              placeholder={"태그를 추가해보세요! 서초동, 와린이, 돔페르뇽"}
            />
          </View>
          <SectionBar top={40} title={"모임안내"} />
          <View
            style={{
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              width: 335,
              paddingVertical: 6,
            }}
          >
            <View style={styles.infoList}>
              <View>
                <Image
                  style={{ width: 24, height: 24 }}
                  source={icons.calendarCheck}
                />
              </View>
              <Button onPress={() => setOpen(true)}>
                {date ? (
                  <Moment
                    locale="ko"
                    element={Text}
                    format="MM.D.(dd) HH:mm"
                    style={{ fontSize: 14, marginLeft: 5 }}
                  >
                    {date}
                  </Moment>
                ) : (
                  "시간선택"
                )}
              </Button>
            </View>
            <PaperProvider theme={theme}>
              {open && (
                <DatePickerModal
                  mode="single"
                  visible={open}
                  onDismiss={onDismissSingle}
                  date={date}
                  onConfirm={onConfirmSingle}
                  saveLabel="저장" // optional
                  label="치팅날짜 선택" // optional
                />
              )}
              {visible && (
                <TimePickerModal
                  visible={visible}
                  onDismiss={onDismiss}
                  onConfirm={onConfirm}
                  label="시간선택" // optional, default 'Select time'
                  cancelLabel="취소" // optional, default: 'Cancel'
                  confirmLabel="저장" // optional, default: 'Ok'
                  animationType="fade" // optional, default is 'none'
                />
              )}
            </PaperProvider>
            <View style={styles.infoList}>
              <Image
                style={{ width: 24, height: 24 }}
                source={icons.numParticipants}
              />
              <TextInput
                dense
                keyboardType="numeric"
                selectionColor={Colors.primary}
                outlineColor={Colors.B_06}
                mode="outlined"
                value={people_limit}
                placeholder="정원을 입력하세요"
                onChangeText={(text) => setPeopleLimit(text)}
                right={<TextInput.Affix text="명" />}
                onFocus={() => {
                  setHideOnNoteFocus(true), console.log("Hide!!");
                }}
                onBlur={() => {
                  setHideOnNoteFocus(false), console.log("Show!!");
                }}
              />
            </View>
            <View style={styles.infoList}>
              <Image
                style={{ width: 24, height: 24, marginRight: 10 }}
                source={icons.wine}
              />
              <View style={{ flexDirection: "column" }}>
                {newWineData.map((item, index) => {
                  return (
                    <TouchableOpacity
                      key={index}
                      style={{ marginRight: 20 }}
                      onPress={() => {
                        setCurrentWine(index), wineModalRef.current.open();
                      }}
                    >
                      <Text
                        style={{
                          color: Colors.B_01,
                          textDecorationLine: "underline",
                        }}
                      >
                        {item.name != ""
                          ? item.name
                          : "와인을 입력하세요" + (index > 0 ? "," : "")}
                      </Text>
                    </TouchableOpacity>
                  );
                })}
                <TouchableOpacity
                  onPress={() => {
                    console.log(newWineData),
                      AddNewWine(),
                      wineModalRef.current.open();
                  }}
                >
                  <Text
                    style={{
                      color: Colors.lightGray,
                      textDecorationLine: "underline",
                    }}
                  >
                    {"와인 추가"}
                  </Text>
                </TouchableOpacity>
              </View>
            </View>
            <View style={styles.infoList}>
              <Image style={{ width: 24, height: 24 }} source={icons.won} />
              <TextInput
                dense
                mode="outlined"
                selectionColor={Colors.primary}
                outlineColor={Colors.B_06}
                placeholder="금액을 입력하세요"
                keyboardType="numeric"
                value={participation_fee}
                onChangeText={(text) => setParticipationFee(text)}
                right={<TextInput.Affix text="원" />}
                onFocus={() => {
                  setHideOnNoteFocus(true), console.log("Hide!!");
                }}
                onBlur={() => {
                  setHideOnNoteFocus(false), console.log("Show!!");
                }}
              />
            </View>
            <View style={styles.infoList}>
              <Image
                style={{ width: 24, height: 24 }}
                source={icons.thickPin}
              />
              <TextInput
                dense
                selectionColor={Colors.primary}
                outlineColor={Colors.B_06}
                mode="outlined"
                value={city + " " + county + " " + place_name}
                placeholder="위치를 입력하세요"
                onBlur={() => {
                  setHideOnNoteFocus(false), console.log("Show!!");
                }}
                onFocus={() => {
                  setHideOnNoteFocus(true), addressModalRef.current.open();
                }}
              />
            </View>
            <View style={styles.infoList}>
              <Image
                style={{ width: 24, height: 24 }}
                source={icons.openchat}
              />
              <TextInput
                dense
                selectionColor={Colors.primary}
                outlineColor={Colors.B_06}
                mode="outlined"
                value={open_chat_link}
                placeholder="오픈카카오톡 링크를 입력하세요"
                onChangeText={(text) => setOpenChatLink(text)}
                onFocus={() => {
                  setHideOnNoteFocus(true), console.log("Hide!!");
                }}
                onBlur={() => {
                  setHideOnNoteFocus(false), console.log("Show!!");
                }}
              />
            </View>
            {currentRegion && (
              <MapView
                style={{ width: 335, height: 200 }}
                initialRegion={currentRegion}
                region={currentRegion}
              >
                {Platform.OS !== "web" && currentRegion !== null && (
                  <Marker
                    coordinate={{
                      latitude: currentRegion.latitude,
                      longitude: currentRegion.longitude,
                    }}
                    title={place_name}
                  />
                )}
              </MapView>
            )}
          </View>
          <SectionBar top={54} title={"치어리더's Note"} />
          <View
            style={{
              flex: 20,
              width: 343,
              marginBottom: hideOnNotefocus ? 10 : 60,
            }}
          >
            {Content()}
          </View>
        </View>
      </ScrollView>
      {!hideOnNotefocus && (
        <View
          style={{
            position: "absolute",
            alignSelf: "center",
            width: 335,
            height: 72,
            bottom: "0%",
          }}
        >
          <ApplyCheeting
            text={"치팅 오픈하기"}
            canOpen={
              title !== "" &&
              place_name !== "" &&
              county !== "" &&
              city !== "" &&
              start_at !== "" &&
              !isNaN(participation_fee) &&
              !isNaN(people_limit) &&
              introduction !== undefined &&
              open_chat_link !== undefined &&
              newWineData !== undefined
            }
            onPress={() => {
              cheeting_id !== "new"
                ? handleUpdateCheeting({
                    title: title,
                    start_at: strftime("%Y-%m-%d %H:%M:%S", start_at),
                    place_name: place_name ? place_name : "",
                    latitude: currentRegion.latitude,
                    longitude: currentRegion.longitude,
                    city: city,
                    county: county,
                    people_limit: parseInt(people_limit),
                    participation_fee: parseInt(participation_fee),
                    open_chat_link: open_chat_link ? open_chat_link : "",
                    hashtags: hashtags,
                    wines: newWineData,
                    image_url: coverImage ? coverImage : "",
                    introduction: introduction,
                    place_detail: address ? address : "",
                  })
                : handleOpenCheeting({
                    title: title,
                    start_at: strftime("%Y-%m-%d %H:%M:%S", start_at),
                    place_name: place_name ? place_name : "",
                    latitude: currentRegion.latitude,
                    longitude: currentRegion.longitude,
                    city: city,
                    county: county,
                    people_limit: parseInt(people_limit),
                    participation_fee: parseInt(participation_fee),
                    open_chat_link: open_chat_link ? open_chat_link : "",
                    hashtags: hashtags,
                    wines: newWineData,
                    image_url: coverImage ? coverImage : "",
                    introduction: introduction,
                    place_detail: address ? address : "",
                  });
            }}
          />
        </View>
      )}
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: Colors.backgroundGray,
    top: 25,
    marginBottom: 25,
  },
  horizontalContainer: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  cardContainer: {
    top: 100,
    width: "98%",
    left: "1%",
  },
  cardList: {
    margin: 15,
    width: "100%",
  },
  userInfo: {
    top: 50,
    left: 50,
    flex: 1,
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: 50,
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: 30,
    fontWeight: "bold",
    fontSize: 30,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: 20,
    fontWeight: "bold",
  },
  sectionBar: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    minWidth: 372,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderColor: "#DDDDDD",
    minWidth: "100%",
    alignItems: "center",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  infoList:{
    flexDirection: "row",
    width: 335,
    paddingVertical: 6,
    justifyContent: "flex-start",
    alignItems: "center",
  }
});
