const background = '#FFFFFF';
const primary = '#7200DA';
const topNavText = '#000';
const black = '#000000';
const footer = '#AAAAAA';
const footerText = '#555555';
const skyblue = '#0067C5';
const gray = '#979797';
const ActiveTab = '#7200DA';
const inActiveTab = '#979797';
const highlight = '#CC0000';
const white = '#FFFFFF';
const lightBlue = '#5CBEFF';
const lightGray = '#A8A8A8';
const grayBtn = '#767676';
const backgroundGray = '#F0F0F0';
const darkgray = '#505050';
const lightBlack = '#4A4A4A';
const warn = "#EF5C5C";
const B_01 = "#202020";
const B_02 = "#4A4A4A";
const B_03 = "#707070";
const B_04 = "#AAAAAA";
const B_05 = "#D7D7D7";
const B_06 = "#F0F0F0";
const B_07 = "#FAFAFA";

export default {
	background,
	primary,
	topNavText,
	black,
	footer,
	footerText,
	skyblue,
	gray,
	ActiveTab,
	inActiveTab,
	highlight,
	white,
	lightBlue,
	lightGray,
	grayBtn,
	backgroundGray,
	darkgray,
	lightBlack,
	warn,
	B_01,
	B_02,
	B_03,
	B_04,
	B_05,
	B_06,
	B_07,
};
