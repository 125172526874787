import env from "../../constants/env";
import { getToken } from "./Token";
import Toast from "react-native-toast-message";

export async function handleLike(data) {
  // await handleUploadImage();
  fetch(`https://${env.apiUrl}/api/v1/common/like`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      res.status;
      console.log(res.status);
      console.log(res);
      (res.status !== 202)
        && Toast.show({
            type: "error",
            text1: "에러발생 !",
            visibilityTime: 1000,
            autoHide: true,
          });
    })
    .catch((error) => {
      // Toast.show({
      //   type: "error",
      //   text1: "네트워크 에러 발생!",
      //   visibilityTime: 1000,
      //   autoHide: true,
      // });

      console.error(error);
    });
}


export async function getReply(type,id) {
  // await handleUploadImage();
  const result = 
  await fetch(`https://${env.apiUrl}/api/v1/common/reply?${type}=${id}`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
  })
  .then(res => res.json())
  .then(json => {
    return (json.data);
  })
    .catch((error) => {
      Toast.show({
        type: "error",
        text1: "네트워크 에러 발생!",
        visibilityTime: 1000,
        autoHide: true,
      });

      console.error(error);
    });
    console.log(result);
    return result;
}


export async function handleReply(data) {
  // await handleUploadImage();
  fetch(`https://${env.apiUrl}/api/v1/common/reply`, {
    method: "POST",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      res.status;
      console.log(res.status);
      console.log(res);
      (res.status !== 201)
        && Toast.show({
            type: "error",
            text1: "에러발생 !",
            visibilityTime: 1000,
            autoHide: true,
          });
    })
    .catch((error) => {
      Toast.show({
        type: "error",
        text1: "네트워크 에러 발생!",
        visibilityTime: 1000,
        autoHide: true,
      });

      console.error(error);
    });
}


export async function updateReply(id,data) {
  // await handleUploadImage();
  fetch(`https://${env.apiUrl}/api/v1/common/reply${id}`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      res.status;
      console.log(res.status);
      console.log(res);
      (res.status !== 202)
        && Toast.show({
            type: "error",
            text1: "에러발생 !",
            visibilityTime: 1000,
            autoHide: true,
          });
    })
    .catch((error) => {
      Toast.show({
        type: "error",
        text1: "네트워크 에러 발생!",
        visibilityTime: 1000,
        autoHide: true,
      });

      console.error(error);
    });
}


export async function deleteReply(id,data) {
  // await handleUploadImage();
  fetch(`https://${env.apiUrl}/api/v1/common/reply${id}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      res.status;
      console.log(res.status);
      console.log(res);
      (res.status !== 202)
        && Toast.show({
            type: "error",
            text1: "에러발생 !",
            visibilityTime: 1000,
            autoHide: true,
          });
    })
    .catch((error) => {
      Toast.show({
        type: "error",
        text1: "네트워크 에러 발생!",
        visibilityTime: 1000,
        autoHide: true,
      });

      console.error(error);
    });
}