import { StatusBar } from 'expo-status-bar';
import React, { useEffect, useState } from 'react';
import { StyleSheet, Platform, View, Image } from 'react-native';
import WebView from 'react-native-webview';
import Layout from '../components/global/Layout';
import 'firebase/firestore';
import WebViewFile from './web/midi/index.html';

export const template = () => String.raw`<!doctype html>

<html lang="en">
<head>
  <meta name='viewport' content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'/>
</head>

<body>
  <h1>Hey React Native</h1>
</body>
</html>`

export default function ({ navigation }) {
  const [systems, setSystems] = useState([]);
  const [isLoading, setLoading] = useState(true);

  return (
    <Layout
      navigation={navigation}
      title="게이크"
      withBack
      size="25"
    >
      <View style={{flex: 1}}>
      <WebView
        style={{flex: 1}}
        originWhitelist={["*"]}
        allowFileAccess={true}
        source={
          {html:template}
          // Platform.OS === "android"
          //   ? {
          //       html: require("./web/midi/index.js")()
          //     }
          //   : WebViewFile
        }
        domStorageEnabled={true}
        allowUniversalAccessFromFileURLs={true}
        allowFileAccessFromFileURLs={true}
        mixedContentMode="always"
      />
{/* 
          <WebView
            style={{flex: 1}}
            source={PolicyHTML}
          /> */}
      </View>
    </Layout>
  );
}
