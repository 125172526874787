import React from "react";
import {
  StyleSheet,
  View,
  TouchableOpacity,
  Image,
  Platform,
  KeyboardAvoidingView,
} from "react-native";
import Text from "../utils/NotoFont";
import Colors from "../../constants/colors";
import { Rating } from "react-native-ratings";
import icons from "../../constants/icons";
import { FlatListSlider } from "react-native-flatlist-slider";
import Moment from "react-moment";
import { nFormatter, numberFormat } from "../utils/Numbers";
// import KeyboardAccessory from "react-native-sticky-keyboard-accessory";
import ProgressBar from "../utils/progressbar";
import Toast from "react-native-toast-message";
import { TextInput } from "react-native-paper";
// import {AutoGrowingTextInput} from 'react-native-autogrow-textinput';

const Button = (props) => {
  return (
    <TouchableOpacity style={styles.button} onPress={props.onPress}>
      <Image source={props.source} style={styles.buttonImg} />
    </TouchableOpacity>
  );
};
export const SearchBlock = (props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View style={[styles.searchBlock]}>
        <Text numberOfLines={1} style={styles.searchBlockTxt} bold>
          {props.text}
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export const BigButton = (props) => {
  return (
    <TouchableOpacity style={[styles.bigButton]} onPress={props.onPress}>
      <Text style={styles.bigButtonTxt} bold>
        {props.text}
      </Text>
    </TouchableOpacity>
  );
};

export const Divider = (props) => {
  return props.vertical ? (
    <View
      style={[styles.dividerV, { height: props.height ? props.height : 46 }]}
    ></View>
  ) : (
    <View style={styles.divider}></View>
  );
};

export const SectionList = (props) => {
  return (
    <TouchableOpacity style={{ ...props.style }} onPress={props.onPress}>
      <View
        style={{
          flex: 1,
          minWidth: 327,
          minHeight: 52,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View style={{ flex: 9, flexDirection: "row", alignItems: "center" }}>
          <Image
            style={
              props.isActive
                ? styles.activeSectionImg
                : styles.inActiveSectionImg
            }
            source={props.imgA}
          />
          <Text
            style={
              props.isActive
                ? styles.activeSectionTxt
                : styles.inActiveSectionTxt
            }
          >
            {props.text}
          </Text>
        </View>
        <View style={{ flex: 1 }}>
          <Image
            style={
              props.isActive
                ? styles.activeSectionImg
                : styles.inActiveSectionImg
            }
            source={props.imgB}
          />
        </View>
      </View>
    </TouchableOpacity>
  );
};

export const ArtistItem = (props) => {
  return (
    <View
      style={{
        ...props.style,
        flex: 1,
        minWidth: 300,
        minHeight: 60,
        flexDirection: "row",
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <View style={{ flex: 1, flexDirection: "row" }}>
        <Image source={props.image} style={styles.artistProfile} />
      </View>
      <View style={[styles.titleContainer, { flex: 5, marginLeft: 12 }]}>
        <Text numberOfLines={1} style={styles.title} bold>
          {props.title}
        </Text>
        <Text numberOfLines={1} style={styles.artist}>
          {props.artist}
        </Text>
      </View>
    </View>
  );
};

const Label = (props) => {
  return (
    <View
      style={{
        alignSelf: "flex-end",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        top: -15,
        right: 16,
        backgroundColor: props.soldout ? Colors.lightGray : Colors.primary,
        width: 72,
        height: 28,
      }}
    >
      { props.my ?<Text style={styles.labelTxt}>
        { props.labelTxt }
      </Text> : <Moment locale="ko" fromNow element={Text} style={styles.labelTxt}>
        {props.date}
      </Moment>}
    </View>
  );
};

const TitleContainer = (props) => {
  return (
    <View style={{ flex: 1, width: 307, height: 26, top: -25, zIndex: 1, marginBottom: 10}}>
      <Text numberOfLines={1} style={styles.title} bold>
        {props.name}
      </Text>
    </View>
  );
};

export const Profile = (props) => {
  return (
    <View>
      {props.withBadge ? (
        <View style={{ flex: 1, top: -20 }}>
          <Image
            source={require("../../assets/crown.png")}
            style={{
              width: 20,
              height: 20,
              alignSelf: "flex-start",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              top: 14,
              left: -4,
              zIndex: 1,
            }}
          />
          <Image
            source={
              props.image
                ? { uri: props.image }
                : require("../../assets/icon.png")
            }
            style={{ width: 54, height: 54, borderRadius: 27 }}
          />
        </View>
      ) : (
        <View>
          <Image
            source={
              props.image
                ? { uri: props.image }
                : require("../../assets/icon.png")
            }
            style={{
              width: props.size,
              height: props.size,
              borderRadius: props.size / 2,
            }}
          />
        </View>
      )}
    </View>
  );
};
Profile.defaultProps = {
  size: 54,
};

const PercentWithLabel = (props) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Text style={{ marginRight: 4, color: Colors.gray }}>{props.text}</Text>
      <ProgressBar width={90} value={props.progress} />
      <Text
        style={{ marginLeft: 8, color: Colors.gray, alignSelf: "flex-end" }}
      >
        {props.progress + "%"}
      </Text>
    </View>
  );
};

export const ProfileRight = (props) => {
  return (
    <View style={{ flex: 1, flexDirection: "column", width: 156, height: 50 }}>
      <PercentWithLabel text={props.text1} progress={props.progress1} />
      <PercentWithLabel text={props.text2} progress={props.progress2} />
      <PercentWithLabel text={props.text3} progress={props.progress3} />
    </View>
  );
};

export const MeetingContentL = (props) => {
  return (
    <View
      style={{
        maxHeight: 54,
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Profile image={props.image} />
      <View
        style={{
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Text
          numberOfLines={1}
          style={{
            fontSize: 12,
            marginLeft: 5,
            color: Colors.black,
          }}
          bold
        >
          {props.cheerLeaderName}
        </Text>
      </View>
    </View>
  );
};

const MeetingContentRA = (props) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <View
        style={{
          flex: 2,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: -50
        }}
      >
        <Text style={styles.pretitle}>{"모임 정원"}</Text>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Image
            style={{ width: 20, height: 20, marginRight: 7 }}
            source={icons.members}
          />
          <Text>{props.totalMembers + "명"}</Text>
        </View>
      </View>
      <View style={{ flex: 1, alignItems: "center"}}>
        <Divider vertical />
      </View>
      <View
        style={{
          flex: 2,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: -50
        }}
      >
        <Text style={styles.pretitle}>{"확정 정원"}</Text>
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Image
            style={{ width: 20, height: 20, marginRight: 7 }}
            source={icons.membersVerified}
          />
          <Text>{props.joinedMembers + "명"}</Text>
        </View>
      </View>
    </View>
  );
};

const MeetingContentRB = (props) => {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <View
        style={{
          flex: 2,
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          top: -5,
        }}
      >
        <Text>{"참석자"}</Text>
        <View
          style={{
            flex: 1,
            top: -15,
          }}
        >
          {props.participants ? (
            <ParticipantsProfile participants={props.participants} />
          ) : (
            <></>
          )}
        </View>
      </View>
    </View>
  );
};

const MeetingContent = (props) => {
  return (
    <View
      style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}
    >
      {/* Left  */}
      <MeetingContentL
        cheerLeaderName={props.cheerLeaderName}
        image={props.image}
        level={props.level}
        review={props.review}
      />

      {/* Right  */}
      {props.type === "A" && (
        <MeetingContentRA
          totalMembers={props.totalMembers}
          joinedMembers={props.joinedMembers}
        />
      )}
      {props.type === "B" && (
        <MeetingContentRB
          totalMembers={props.totalMembers}
          joinedMembers={props.joinedMembers}
          participants={props.participants}
        />
      )}
    </View>
  );
};

export const Tags = (props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        flexWrap: "wrap",
        alignItems: "center",
        marginTop: props.top,
        marginBottom: props.bottom,
        width: props.width,
      }}
    >
      {props.tags.map((tag, i) => {
        return (
          <TouchableOpacity key={i}>
            <View style={styles.tagBlock}>
              <Text numberOfLines={1} style={styles.tagTxt} bold>
                {"#" + tag}
              </Text>
            </View>
          </TouchableOpacity>
        );
      })}
    </View>
  );
};

const LDP = (props) => {
  return (
    <View
      style={{ flex: 1, flexDirection: "row", justifyContent: "space-between" }}
    >
      <View
        style={{ flex: 4, flexDirection: "row", justifyContent: "flex-start" }}
      >
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          <Image style={{ width: 14, height: 14 }} source={icons.pin} />
          <Text style={{ fontSize: 14, marginLeft: 5 }}>{props.location}</Text>
        </View>
        <View
          style={{
            flexDirection: "row",
            justifyContent: "flex-start",
            alignItems: "center",
            left: 12,
          }}
        >
          <Image style={{ width: 14, height: 14 }} source={icons.date} />
          <Moment
            locale="ko"
            element={Text}
            format="MM.D.(dd) HH:mm"
            style={{ fontSize: 14, marginLeft: 5 }}
          >
            {props.date}
          </Moment>
        </View>
      </View>
      <View
        style={{ flex: 1.5, alignItems: "center", justifyContent: "center" }}
      >
        <Text>{numberFormat(props.price)}</Text>
      </View>
    </View>
  );
};

const FeedButton = (props) => {
  return (
    <TouchableOpacity
      onPress={props.onPress}
      style={[
        {
          width: "100%",
          height: 44,
          alignItems: "center",
          justifyContent: "center",
          borderBottomLeftRadius: 4,
          borderBottomRightRadius: 4,
        },
        props.canWrite
          ? { backgroundColor: Colors.primary }
          : { backgroundColor: Colors.gray },
      ]}
    >
      <Text
        style={{ fontSize: 16, alignSelf: "center", color: Colors.white }}
        bold
      >
        {props.canWrite ? "피드 쓰기" : "피드 보기"}
      </Text>
    </TouchableOpacity>
  );
};

const ParticipantsProfile = (props) => {
  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      {props.participants.slice(0, 6).map((item, i) => {
        return (
          <Image
            key={i}
            style={{ width: 32, height: 32, marginLeft: -16 }}
            source={item.img ? item.img : require("../../assets/icon.png")}
          />
        );
      })}
      {props.participants.length > 6 ? (
        <Image
          style={{ width: 32, height: 32, marginLeft: -16 }}
          source={icons.roundMore}
        />
      ) : (
        <></>
      )}
    </View>
  );
};
export const MeetingListItem = React.memo((props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View
        opacity={props.soldout ? 0.5 : 1}
        style={{
          ...props.style,
          flex: 1,
          minWidth: 343,
          maxWidth: 343,
          minHeight: 210,
          maxHeight: props.my ? 274 : 236,
          marginVertical: 16,
          marginHorizontal: 12,
          flexDirection: "column",
          justifyContent: "flex-start",
          backgroundColor: Colors.white,
          borderRadius: 4,
        }}
      >
        <Label my={props.my} date={props.date} labelTxt={props.labelTxt} />
        <View
          style={{
            flex: 1,
            maxWidth: 312,
            flexDirection: "column",
            justifyContent: "flex-start",
            alignSelf: "center",
          }}
        >
          <TitleContainer
            numberOfLines={1}
            style={{ flex: 1 }}
            name={props.title}
          />
          <MeetingContent
            style={{ flex: 2 }}
            // Left Content
            image={props.image}
            level={props.level}
            cheerLeaderName={props.cheerLeaderName}
            badge={props.badge}
            review={props.review}
            // Right Content
            totalMembers={props.totalMembers}
            joinedMembers={props.joinedMembers}
            type={props.type}
            participants={props.participants}
          />
          <Tags top={20} bottom={20} tags={props.tags} />
          <Divider />
          <LDP
            style={{ flex: 2 }}
            location={props.location}
            date={props.date}
            price={props.price}
          />
          {props.my && (
            <View style={{ flex: 1.5 }}>
              <Divider />
              <MeetingCL
                reply_cnt={props.reply_cnt}
                isLiked={props.whether_liked}
              />
            </View>
          )}
        </View>
        {props.feed && props.type === "B" && (
          <FeedButton onPress={props.onFeedPress} canWrite={props.canWrite} />
        )}
      </View>
    </TouchableOpacity>
  );
});

export const SendComment = (props) => {
  return (
    <KeyboardAvoidingView>
      <View
        style={{
          flex: 1,
          width: 375,
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "space-between",
          alignItems: "center",
          marginLeft: 30,
          backgroundColor: Colors.white,
          marginBottom: 10,
        }}
      >
        <Profile image={props.image} size={36} />
        {/* <AutoGrowingTextInput
            value={props.text}
            onChange={props.onChangeText}
            style={{
              fontSize: 14,
              flex: 1,
              color:Colors.B_01,  
            }}
            placeholder={'댓글을 입력하세요...!'}
            placeholderTextColor={Colors.B_04}
            maxHeight={200}
            minHeight={50}
            enableScrollToCaret
          /> */}

        <TextInput
          style={{
            marginLeft: 4,
            flex: 5,
            color: Colors.B_01,
          }}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          dense
          value={props.message}
          mode="outlined"
          selectionColor={Colors.primary}
          outlineColor={Colors.B_06}
          multiline
          placeholder="댓글을 입력하세요..."
          onChangeText={props.onChangeText}
        />
        <TouchableOpacity
          onPress={props.sendMessage}
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          <Image
            style={{
              width: 24,
              height: 24,
              tintColor: props.isActive ? Colors.primary : Colors.inActiveTab,
            }}
            source={icons.send}
          />
        </TouchableOpacity>
      </View>
    </KeyboardAvoidingView>
  );
};

const ListItem = (props) => (
  <View
    style={{
      ...props.style,
      flex: 1,
      minWidth: 343,
      minHeight: 210,
      flexDirection: "column",
      justifyContent: "flex-start",
    }}
  >
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 4,
        top: -40,
        backgroundColor: Colors.primary,
        width: 72,
        height: 28,
      }}
    >
      <Text style={styles.labelTxt} bold>
        {props.labelTxt ? props.labelTxt : "Sold Out"}
      </Text>
    </View>
    <View style={{ flex: 1 }}>
      <Text numberOfLines={1} style={styles.title} bold>
        {props.title}
      </Text>
    </View>
    <View
      style={{ flex: 5, flexDirection: "row", justifyContent: "flex-start" }}
    >
      <Image
        source={props.image ? props.image : require("../../assets/icon.png")}
        style={styles.profile}
      />
      <View style={styles.titleContainer}>
        <Text numberOfLines={1} style={styles.title} bold>
          {props.title}
        </Text>
        <Text numberOfLines={1} style={styles.artist}>
          {props.artist}
        </Text>
      </View>
    </View>
    <View style={styles.buttonContainer}>
      <Button onPress={props.buttonAPress} source={props.imageA} />
      <Button onPress={props.buttonBPress} source={props.imageB} />
    </View>
  </View>
);

export const FeedProfile = (props) => {
  return (
    <View style={{ flexDirection: "row" }}>
      <Image
        source={
          props.profile
            ? { uri: props.profile }
            : require("../../assets/icon.png")
        }
        style={{ width: 42, height: 42, borderRadius: 21 }}
      />
      <View style={{ flexDirection: "column", width: 200, marginLeft: 6 }}>
        <Text
          numberOfLines={1}
          style={{
            fontSize: 15,
            color: Colors.darkgray,
            ...Platform.select({
              ios: {
                top: -10,
              },
              android: {
                top: -10,
              },
              default: {
                top: -10,
              },
            }),
          }}
          bold
        >
          {props.name}
        </Text>
        <Moment
          locale="ko"
          fromNow
          element={Text}
          style={{
            fontSize: 11,
            ...Platform.select({
              ios: {
                top: -30,
              },
              android: {
                top: -30,
              },
              default: {
                top: -30,
              },
            }),
          }}
        >
          {props.date}
        </Moment>
      </View>
    </View>
  );
};

const MoreButton = (props) => {
  return (
    <TouchableOpacity
      style={{
        flex: 1,
        width: 16,
        height: 4,
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Image style={{ width: 16, height: 4 }} source={icons.moreH} />
    </TouchableOpacity>
  );
};

const FeedContent = (props) => {
  return (
    <View style={{ marginTop: 14 }}>
      <Text
        numberOfLines={3}
        style={{ alignSelf: "center", maxHeight: 85, width: 319, top: -30 }}
      >
        {props.content}
      </Text>
      {props.thumbnailImage && (
        <Image
          style={{ width: 343, height: 193, marginTop: 16 }}
          source={{ uri: props.thumbnailImage }}
        />
      )}
    </View>
  );
};
const FeedLCS = (props) => {
  return (
    <View
      style={{
        flex: 1,
        marginTop: 25,
        width: 319,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <TouchableOpacity onPress={props.onPressLike}>
          <Image
            style={{ width: 20, height: 20 }}
            source={props.isLiked ? icons.likeA : icons.like}
          />
        </TouchableOpacity>
        <Text style={{ fontSize: 15, marginLeft: 5 }}>
          {nFormatter(props.likes, 1)}
        </Text>
        <TouchableOpacity>
          <Image
            style={{ width: 20, height: 20, marginLeft: 10 }}
            source={icons.comment}
          />
        </TouchableOpacity>
        <Text style={{ fontSize: 15, marginLeft: 5 }}>
          {nFormatter(props.comments, 1)}
        </Text>
      </View>
    </View>
  );
};
export const FeedItem = React.memo((props) => {
  return (
    <TouchableOpacity onPress={props.onPress}>
      <View
        style={{
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: Colors.white,
          width: 343,
          borderRadius: 4,
          marginVertical: 12,
        }}
      >
        <View
          style={{
            width: 319,
            marginTop: 14,
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <View style={{ flex: 15 }}>
            <FeedProfile
              profile={props.profile}
              name={props.name}
              date={props.date}
            />
          </View>
          <View style={{ flex: 1, alignSelf: "flex-end", top: -21 }}>
            <MoreButton />
          </View>
        </View>
        <FeedContent
          content={props.content}
          thumbnailImage={props.thumbnailImage}
        />
        <FeedLCS
          likes={props.likes}
          onPressLike={props.onPressLike}
          comments={props.comments}
          shares={props.shares}
          onPressShares={props.onPressShares}
          isLiked={props.isLiked}
        />
        <View style={{ marginTop: 12 }}>
          <Divider />
        </View>
        <Tags top={10} bottom={10} width={319} tags={props.tags} />
      </View>
    </TouchableOpacity>
  );
});

const MeetingCL = (props) => {
  return (
    <View
      style={{
        flex: 1,
        marginTop: 12,
        marginBottom: 15,
        width: 319,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <View style={{flexDirection: "row" }}>
        <TouchableOpacity
          style={{ justifyContent: "center", alignItems: "center" }}
        >
          <Image
            style={{ width: 24, height: 24, marginLeft: 5 }}
            source={icons.comment}
          />
        </TouchableOpacity>
        <Text style={{ fontSize: 12, marginLeft: 5 }}>
          {nFormatter(props.reply_cnt, 1)}
        </Text>
      </View>
      <View>
        <TouchableOpacity
          onPress={props.onPressLike}
          style={{ justifyContent: "center", alignItems: "center", marginRight: 10 }}
        >
          <Image
            style={{ width: 24, height: 24 }}
            source={props.isLiked ? icons.heartA : icons.heart}
          />
        </TouchableOpacity>
      </View>
    </View>
  );
};

export const CheetingMemberListItem = (props) => {
  return (
    <View
      opacity={props.rejected ? 0.5 : 1}
      style={{ paddingVertical: 6, flexDirection: "row", width: 335 }}
    >
      <Image
        source={
          props.profile
            ? { uri: props.profile }
            : require("../../assets/icon.png")
        }
        style={{ width: 64, height: 64, borderRadius: 32 }}
      />
      <View style={{ flexDirection: "column", marginLeft: 12, width: 150 }}>
        <Text style={{ fontSize: 16, color: Colors.darkgray }} bold>
          {props.name}
        </Text>
        <Text style={{ fontSize: 14, color: Colors.darkgray }}>
          {props.introduction ? props.introduction : "자기소개가 없습니다."}
        </Text>
      </View>
      {props.isWaiting && props.isMine && (
        <View
          style={{
            flexDirection: "row",
            alignSelf: "center",
            justifyContent: "center",
            width: 150,
            height: 40,
          }}
        >
          <TouchableOpacity
            style={{
              backgroundColor: Colors.primary,
              borderRadius: 4,
              alignItems: "center",
              justifyContent: "center",
              width: 40,
              height: 40,
            }}
            onPress={props.buttonA}
          >
            <Text style={{ fontSize: 14, color: Colors.white }} bold>
              {"수락"}
            </Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              backgroundColor: Colors.lightGray,
              borderRadius: 4,
              alignItems: "center",
              justifyContent: "center",
              marginLeft: 5,
              width: 40,
              height: 40,
            }}
            onPress={props.buttonB}
          >
            <Text style={{ fontSize: 14, color: Colors.white }} bold>
              {"거절"}
            </Text>
          </TouchableOpacity>
        </View>
      )}
    </View>
  );
};

const MoreLessComponent = ({ truncatedText, fullText }) => {
  const [more, setMore] = React.useState(false);
  return (
    <View style={{ flexDirection: "row" }}>
      <TouchableOpacity onPress={() => setMore(!more)}>
        <Text
          style={{
            marginTop: 4,
            color: Colors.B_01,
            marginBottom: 12,
            marginLeft: 14,
          }}
        >
          {!more ? `${truncatedText}` : fullText}
          <Text style={{ fontSize: 12, color: Colors.B_03, top: -12 }}>
            {more ? "  줄이기" : "...더보기"}
          </Text>
        </Text>
      </TouchableOpacity>
    </View>
  );
};

const MoreInfo = ({ text, linesToTruncate, newStyle }) => {
  const [clippedText, setClippedText] = React.useState(text.substr(0, 30));
  // setClippedText(text);

  return clippedText && clippedText.length > 29 ? (
    <MoreLessComponent truncatedText={clippedText} fullText={text} />
  ) : (
    <Text
      style={{
        marginTop: 4,
        color: Colors.B_01,
        marginBottom: 12,
        marginLeft: 14,
      }}
      numberOfLines={linesToTruncate}
      ellipsizeMode={"tail"}
      // onTextLayout={(event) => {
      //   console.log("event");
      //   //get all lines
      //   const { lines } = event.nativeEvent;
      //   //get lines after it truncate
      //   let text = lines
      //     .splice(0, linesToTruncate)
      //     .map((line) => line.text)
      //     .join('');
      //   //substring with some random digit, this might need more work here based on the font size
      //   //
      //   console.log(lines);
      //   setClippedText(text.substr(0, text.length-15));
      // }}
    >
      {text}
    </Text>
  );
};

export const ReplyListItem = (props) => {
  return (
    <View style={{ flexDirection: "row", marginBottom: 12, width: 343 }}>
      <Profile image={props.image} size={36} />
      <View
        style={{
          width: 303,
          backgroundColor: Colors.B_06,
          borderRadius: 4,
          marginLeft: 4,
        }}
      >
        <View
          style={{
            flexDirection: "row",
            marginTop: 11,
            marginLeft: 14,
            alignItems: "flex-end",
          }}
        >
          <Text style={{ color: Colors.B_03, marginRight: 5 }} bold>
            {props.name}
          </Text>
          <Moment
            locale="ko"
            fromNow
            element={Text}
            style={{ color: Colors.B_04, fontSize: 12 }}
          >
            {props.date}
          </Moment>
        </View>
        {Platform.OS !== "web" ? (
          <MoreInfo text={props.text} linesToTruncate={2} />
        ) : (
          <Text
            style={{
              marginTop: 4,
              color: Colors.B_01,
              marginBottom: 12,
              marginLeft: 14,
            }}
          >
            {props.text}
          </Text>
        )}
      </View>
    </View>
  );
};

export const InfoListItem = (props) => {
  return (
    <View
      style={{
        flexDirection: "row",
        width: 335,
        paddingVertical: 6,
        justifyContent: "flex-start",
        alignItems: "center",
      }}
    >
      <View>
        <Image style={{ width: 24, height: 24 }} source={props.image} />
      </View>
      {props.isWine ? (
        <View style={{ marginLeft: 8, flexDirection: "row" }}>
          {props.wines.map((item, index) => {
            return (
              <TouchableOpacity
                key={index}
                onPress={props.onPress}
                style={{ marginRight: 5 }}
              >
                <Text
                  style={{
                    color: Colors.lightGray,
                    fontSize: 15,
                    textDecorationLine: "underline",
                  }}
                >
                  {item.name}
                </Text>
              </TouchableOpacity>
            );
          })}
        </View>
      ) : (
        <View style={{ marginLeft: 8 }}>
          {props.isDate ? (
            <Moment
              locale="ko"
              element={Text}
              format="MM.D.(dd) HH:mm"
              style={{ color: Colors.lightGray, fontSize: 15 }}
            >
              {props.text}
            </Moment>
          ) : props.isChat ? (
            <Text
              style={{ color: Colors.lightGray, fontSize: 15 }}
              textDecorationLines={"underline"}
            >
              {props.canView
                ? props.text
                : "참석 확정 후 링크를 확인할 수 있습니다"}
            </Text>
          ) : (
            <Text style={{ color: Colors.lightGray, fontSize: 15 }}>
              {props.text}
            </Text>
          )}
        </View>
      )}
    </View>
  );
};
const styles = StyleSheet.create({
  labelTxt: {
    fontSize: 12,
    color: Colors.white,
    fontWeight: "bold",
    marginBottom: 2
  },
  title: {
    fontSize: 20,
    minWidth: 220,
    fontWeight: "bold",
    color: Colors.white,
  },
  content: {
    textAlign: "right",
    marginRight: 15,
    fontSize: 20,
  },
  profile: {
    paddingLeft: 18,
    width: 54,
    height: 54,
    borderRadius: 27,
  },
  titleContainer: {
    flex: 1,
    flexDirection: "column",
    marginLeft: 16,
  },
  title: {
    color: Colors.black,
    fontSize: 18,
    marginTop: 8,
  },
  pretitle: {
    color: Colors.footerText,
    fontSize: 14,
    marginBottom: 5,
  },
  artist: {
    fontSize: 12,
    color: Colors.gray,
  },
  button: {
    width: 21,
    height: 21,
  },
  buttonImg: {
    width: "100%",
    height: "100%",
  },
  buttonContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  bigButton: {
    minWidth: 327,
    minHeight: 52,
    borderWidth: 2,
    borderRadius: 10,
    borderColor: Colors.ActiveTab,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  bigButtonTxt: {
    alignSelf: "center",
    color: Colors.ActiveTab,
    fontSize: 16,
  },
  artistProfile: {
    width: 50,
    height: 50,
  },
  activeSectionImg: {
    width: 22,
    height: 22,
    tintColor: Colors.white,
  },
  inActiveSectionImg: {
    width: 22,
    height: 22,
    tintColor: Colors.gray,
  },
  activeSectionTxt: {
    marginLeft: 16,
    fontSize: 16,
    color: Colors.white,
  },
  inActiveSectionTxt: {
    marginLeft: 16,
    fontSize: 16,
    color: Colors.gray,
  },
  divider: {
    width: 312,
    borderWidth: 1,
    height: 1,
    borderColor: "#EAEAEA",
  },
  dividerV: {
    width: 1,
    height: 46,
    borderWidth: 1,
    borderColor: "#EAEAEA",
  },

  searchBlock: {
    minWidth: 12,
    maxWidth: 323,
    height: 27,
    borderWidth: 1,
    borderColor: Colors.gray,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginHorizontal: 5,
    marginVertical: 5,
  },
  searchBlockTxt: {
    fontSize: 12,
    paddingHorizontal: 10,
    alignSelf: "center",
    color: Colors.gray,
  },
  tagBlock: {
    minWidth: 12,
    maxWidth: 323,
    height: 22,
    maxHeight: 22,
    minHeight: 22,
    borderWidth: 1,
    borderRadius: 4,
    borderColor: Colors.gray,
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
    marginHorizontal: 5,
    marginVertical: 5,
  },
  tagTxt: {
    fontSize: 11,
    paddingHorizontal: 10,
    alignSelf: "center",
    color: Colors.gray,
  },
});
export default ListItem;
