import React, { useState, useEffect } from "react";
import  AppLoading  from "expo-app-loading";
import { Asset } from "expo-asset";
import { StyleSheet, Linking, Platform, View, Image, TouchableOpacity, } from "react-native";
import {
  SafeAreaView,
  SafeAreaProvider,
  initialWindowMetrics,
} from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import { useFonts } from "expo-font";
import AppNavigator from "./navigation/AppNavigator";
import { AuthProvider } from "./provider/AuthProvider";
import icons from "./constants/icons";
import Moment from "react-moment";
import "moment/locale/ko";
// Sets the moment instance to use.
import AppIntroSlider from "react-native-app-intro-slider";
import Colors from "./constants/colors";
import Text from "./components/utils/NotoFont";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Toast from 'react-native-toast-message';
import { DefaultTheme, Provider as PaperProvider } from 'react-native-paper';

// Set the locale for every react-moment instance to Korean.
Moment.globalLocale = "ko";

const slides = [
  {
    key: "one",
    text:
      "더치어스는 지역기반 와인 더치페이 매칭 플랫폼 서비스로, \n내 주변 동네 와인바, 주류취급, 식당을 콜키지 비용없이\n더치페이로 함께 즐길수 있는 소셜커뮤니티 입니다!",
    title: "같은 동네,같은 취향\n함께 즐기고\n더치페이로 나눠요!",
    image: require("./assets/intro/1.png"),
  },
  {
    key: "two",
    title: "취향이 통하는 동네 친구들을 만나세요!",
    text: "매일 새롭게 열리는 와인, 위스키, 전통주 등 번개모임에서",
    image: require("./assets/intro/2.png"),
  },
  {
    key: "three",
    title: "마음 편하게 와인 모임에 참여해요",
    text: "여러분만의 프로필을 설정하고",
    image: require("./assets/intro/3.png"),
  },
  {
    key: "four",
    title: "모임 후기로 살펴봐요",
    text: "와인과 함께한 특별한 경험들",
    image: require("./assets/intro/4.png"),
  },
];

export default function App(props) {

  let [fontsLoaded] = useFonts({
    NotoR: require("./assets/fonts/NotoSansKR-Regular.otf"),
    NotoB: require("./assets/fonts/NotoSansKR-Bold.otf"),
  });
  const [isReady, setIsReady] = useState(false);
  const [showRealApp, setShowRealApp] = useState(false);

const toastConfig = {
  success: (internalState) => (
    <View
      style={{ height: 60, width: 335, top:-50, backgroundColor: Colors.primary, borderRadius:4,justifyContent: "center", alignItems: "center" }}
    >
      <Text style={{color:Colors.white, alignSelf:"center"}} bold>{internalState.text1}</Text>
    </View>
  ),
  error: (internalState) => (
    <View
      style={{ height: 60, width: 335,  top:-50, backgroundColor: Colors.warn, borderRadius:4 , justifyContent: "center", alignItems: "center"}}
    >
      <Text style={{color:Colors.white, alignSelf:"center",}} bold>{internalState.text1}</Text>
    </View>
  ),
  info: () => {},
  any_custom_type: () => {},
};

const Button = (props) => {
  return(
    <TouchableOpacity onPress={props.onPress}>
      <Text>{props.title}</Text>
    </TouchableOpacity>
  )
}
const saveFirstState = async (value) => {
  try {
    await AsyncStorage.setItem('@intro', 'true')
  } catch (e) {
    // saving error
  }
}

const getFirstState = async () => {
  try {
    const value = await AsyncStorage.getItem('@intro')
    if(value !== null) {
      // value previously stored
      setShowRealApp(true);
    }
  } catch(e) {
    // error reading value
  }
}
// useEffect(() => {
  AsyncStorage.getItem('@intro').then((value)=> {
    if(value !== null) {
      // value previously stored
      setShowRealApp(true);
    }  
  })
// },[])

// if (permission.status !== 'granted' && Platform.OS !== 'web') {
//      return (
//         <View>
//             <Text>카메라사용 허용이 필요합니다.</Text>
//             {permission?.canAskAgain
//                 ? <Button onPress={askPermission} title='권한허용' />
//                 : <Button onPress={Linking.openSettings} title='앱세팅열기' />
//             }
//         </View>
//     );
// }

  const renderSliderItem = ({ item }) => {
    return item.key == "one" ? (
      <View style={styles.slide}>
        <View style={{ top: 65 }}>
          <Text style={styles.title} bold>
            {item.title}
          </Text>
          <Text style={[styles.content, { marginTop: 30 }]}>{item.text}</Text>
        </View>
        <Image
          source={item.image}
          style={{ width: "100%", height: 566, top: 100 }}
        />
      </View>
    ) : (
      <View style={styles.slide}>
        <View style={{ top: 82 }}>
          <Text style={styles.content}>{item.text}</Text>
          <Text style={styles.title} bold>
            {item.title}
          </Text>
        </View>
        <View style={{ top: 132 }}>
          <Image source={item.image} style={styles.image} />
        </View>
      </View>
    );
  };

  const onDone = () => {
    // User finished the introduction. Show real app through
    // navigation or simply by controlling state
    setShowRealApp(true);
    saveFirstState();
  };

  const renderNextButton = () => {
    return (
      <View style={styles.sliderButton}>
        <Text style={styles.sliderButtonTxt} bold>
          다음
        </Text>
      </View>
    );
  };
  const renderDoneButton = () => {
    return (
      <View style={styles.sliderButton}>
        <Text style={styles.sliderButtonTxt} bold>
          시작하기
        </Text>
      </View>
    );
  };

  const theme = {
    ...DefaultTheme,
    // Specify custom property in nested object
    colors: {
      // background: Colors.white,
      primary: Colors.primary,
      accent: Colors.white,
      onSurface:Colors.white,
    },
    mode:'adaptive'
  };
  


  return fontsLoaded ? (
    <SafeAreaProvider initialMetrics={initialWindowMetrics}>
      <SafeAreaView style={styles.container}>
        <StatusBar style="auto" translucent />
        {showRealApp ? (
          <PaperProvider theme={theme}>
          <AuthProvider>
            <AppNavigator />
            <Toast config={toastConfig} ref={(ref) => Toast.setRef(ref)} />
          </AuthProvider>
          </PaperProvider>
        ) : (
          <AppIntroSlider
            dotStyle={{
              backgroundColor: Colors.white,
              borderColor: Colors.primary,
              borderWidth: 1,
            }}
            activeDotStyle={{ backgroundColor: Colors.primary }}
            bottomButton
            renderItem={renderSliderItem}
            data={slides}
            renderDoneButton={renderDoneButton}
            renderNextButton={renderNextButton}
            onDone={onDone}
          />
        )}
      </SafeAreaView>
    </SafeAreaProvider>
  ) : (
    <AppLoading
      startAsync={loadResourcesAsync}
      onError={() => handleLoadingError}
      onFinish={() => setIsReady(true)}
    />
  );
}

async function loadResourcesAsync() {
  // load all resources such as images, fonts, etc.
  const cacheIcons = () => icons.map((icon) => iconArray.push(icon));
  const iconArray = [];

  return await Promise.all([
    Asset.loadAsync([
      require("./assets/icon.png"),
      require("./assets/introImage.png"),
    ]),
  ]);
  // await SplashScreen.hideAsync();
}

function handleLoadingError(error) {
  // In this case, you might want to report the error to your error reporting
  // service, for example Sentry
  console.warn(error);
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  slide: {
    flex: 1,
    resizeMode: "cover",
    maxHeight: 715,
    overflow: "hidden",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    top: 130,
    fontSize: 14,
    color: Colors.gray,
    alignSelf: "center",
  },
  title: {
    top: 10,
    fontSize: 20,
    color: Colors.black,
    alignSelf: "center",
  },
  image: {
    top: 47,
    width: 277,
    height: 567,
  },
  sliderButton: {
    bottom: 10,
    width: 337,
    height: 52,
    backgroundColor: Colors.primary,
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
  },
  sliderButtonTxt: {
    color: Colors.white,
    alignSelf: "center",
  },
});
