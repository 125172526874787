import React, { useState, useEffect, useRef,useContext } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  Image,
  // FlatList,
  TextInput,
  Dimensions,
  Platform,
} from "react-native";
import * as firebase from "firebase";
import Text from "../components/utils/NotoFont";
import Layout from "../components/global/Layout";
import icons from "../constants/icons";
import Colors from "../constants/colors";
import * as ImagePicker from "expo-image-picker";
import { Divider, Profile,Tags } from "../components/global/ListItem";
import env from "../constants/env";
import Modal from "react-native-modalbox";
import {getToken} from "../components/utils/Token";
import {AuthContext} from "../provider/AuthProvider";
import axios from "axios";
import {uploadSingleImage} from "../components/utils/UploadImage";
import Toast from "react-native-toast-message";

export default function ({ route, navigation }) {
  const { community_id = null } = route.params ?? {};
  const modalRef = useRef(null);
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get(
    "window"
  );
  const { userInfo } = useContext(AuthContext);
  async function getCommunityDetail() {
    await fetch(`https://${env.apiUrl}/api/v1/community/${community_id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        setCurrentTopic(topicValues.indexOf(json.data.topic));
        setContent(json.data.content);
        setImages(json.data.image_urls);
        setHashtags(json.data.hashtags);
        setHashtagText(json.data.hashtags);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    (async () => {
      console.log("GET DETAIL!");
      (community_id != "new" && await getCommunityDetail());
    })();
  }, []);

  async function handleUpdateFeed(data) {
    console.log("Upload Clicked!");

    fetch(`https://${env.apiUrl}/api/v1/community/${community_id}`, {
      method: "PUT",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then(res => {
      console.log(res.status);
      (res.status === 202) ? 
        Toast.show({
          type: "success",
          text1: "커뮤니티에 글이 업데이트 되었습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        }) : Toast.show({
          type: "error",
          text1: "글 업데이트 실패.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        }) ;
        navigation.navigate('CommunityDetail', {
          community_id: community_id,
        })
        })
      .catch((error) => {
        Toast.show({
          type: "success",
          text1: "커뮤니티에 글이 업데이트 되었습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        }) ;
        navigation.navigate('CommunityDetail', {
          community_id: community_id,
        });

        console.error(error);
      });
  }

  async function handleUploadFeed(data) {
    console.log("Upload Clicked!");

    fetch(`https://${env.apiUrl}/api/v1/community`, {
      method: "POST",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    .then(res => {
      console.log(res.status);
      (res.status === 201) ? 
        Toast.show({
          type: "success",
          text1: "커뮤니티에 글이 업로드 되었습니다.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        }) : Toast.show({
          type: "error",
          text1: "글 업로드 실패.",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        }) ;
        navigation.navigate('Community');
      })
      .catch((error) => {
        console.error(error);
      });
  }
  const topicNames = [
    "모임후기",
    "와인 정보",
    "와인 공구",
    "마이 셀러 스토리",
    "지역홍보글",
    "시음후기",
  ];
  const topicValues = [
    "CHEETING_REVIEW",
    "WINE_INFO",
    "JOINT_PURCHASE",
    "MY_SELLER",
    "LOCAL_PROMOTION",
    "APP_REVIEW",
  ];
  const [images, setImages] = useState([]);
  const [currentTopic, setCurrentTopic] = useState(null);

  useEffect(() => {
    (async () => {
      if (Platform.OS !== "web") {
        const {
          status,
        } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (status !== "granted") {
          alert("Sorry, we need camera roll permissions to make this work!");
        }
      }
    })();
    console.log("userInfo",userInfo);
  }, [images]);

  const TopicBlock = (props) => {
    return (
      <View style={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
        {topicNames.map((item, index) => {
          return (
            <TouchableOpacity
              key={index}
              onPress={() => {
                setCurrentTopic(index), modalRef.current.close();
              }}
              style={{ width: 335, height: 56, justifyContent: "center" }}
            >
              <Text
                style={{
                  fontSize: 14,
                  color: Colors.gray,
                  alignSelf: "flex-start",
                }}
              >
                {item}
              </Text>
            </TouchableOpacity>
          );
        })}
      </View>
    );
  };

  const BottomModal = () => {
    return (
      <Modal
        position={"bottom"}
        style={{
          backgroundColor: Colors.white,
          justifyContent: "center",
          alignItems: "center",
          width: 375,
          height: 350,
        }}
        ref={modalRef}
        swipeToClose={true}
        // onClosed={onClose}
        // onOpened={onOpen}
      >
        <TopicBlock />
      </Modal>
    );
  };
  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
    });

    if (!result.cancelled) {
      let array = [...images];
      console.log("Image selecetd");
      console.log(result);
      let imageUrl = await uploadSingleImage(result, `https://${env.apiUrl}/api/v1/s3/upload/image`);
      array.push(imageUrl);
      console.log("array:" + array);
      setImages(array);    
    }
  };


  const [content, setContent] = useState("");
  const [hashtagText, setHashtagText] = useState("");
  const [hashtags, setHashtags] = useState([]);

  const removeImage = (index) => {
    let array = [...images];
    array.splice(index, 1);
    setImages(array);
  };

  const ImageBlock = (props) => {
    return (
      <View
        style={{
          backgroundColor: Colors.gray,
          height: 64,
          width: 64,
          borderRadius: 4,
          marginHorizontal: 10,
        }}
      >
        <Image
          style={{ height: 64, width: 64, borderRadius: 4 }}
          source={{ uri: props.image }}
        />
        <TouchableOpacity
          onPress={() => {
            removeImage(props.id);
          }}
        >
          <Image
            style={{
              width: 24,
              height: 24,
              position: "absolute",
              left: -10,
              top: -70,
              zIndex: 10,
            }}
            source={icons.remove}
          />
        </TouchableOpacity>
      </View>
    );
  };


  // {image && <Image source={{ uri: image }} style={{ width: 200, height: 200 }} />}
  const ImageBlocks = () => {
    return (
      <ScrollView
        horizontal
        style={{ marginTop: 10, height: 84, width: 375, flexDirection: "row" }}
      >
        <CameraButton totalImages={images.length} maxImages={10} />

        {images.map((item, id) => {
          return <ImageBlock key={id} id={id} image={item} />;
        })}
      </ScrollView>
    );
  };

  const CameraButton = (props) => {
    return (
      <TouchableOpacity
        style={{
          marginLeft: 20,
          backgroundColor: Colors.white,
          borderColor: Colors.gray,
          borderWidth: 1,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: 64,
          height: 64,
          borderRadius: 4,
        }}
        onPress={images.length < 10 ? pickImage : console.log("Max image!")}
      >
        <Image source={icons.camera} style={{ width: 24, height: 24 }} />
        <Text style={{ fontSize: 10, color: Colors.gray }}>
          {props.totalImages + "/" + props.maxImages}
        </Text>
      </TouchableOpacity>
    );
  };

  const Topic = (props) => {
    return (
      <TouchableOpacity
        onPress={props.onPress}
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          height: 44,
          width: 335,
        }}
      >
        <Text style={{ fontSize: 14, color: Colors.gray }} bold>
          {currentTopic !== null
            ? topicNames[currentTopic]
            : "게시글의 주제를 선택해 주세요"}
        </Text>
        <Image source={icons.arrowDown} style={{ width: 24, height: 24 }} />
      </TouchableOpacity>
    );
  };

  return (
    <Layout
      navigation={navigation}
      size={24}
      withBack
      title={"피드쓰기"}
      buttonB
      textB={"업로드"}
      buttonBActive={(currentTopic!==null && content.length >0 )? true:false}
      onPressB={() => {
        console.log("Upload!");
        (currentTopic !== null) ? 
        ( community_id !== 'new' ?
        handleUpdateFeed({
          topic: topicValues[currentTopic],
          content: content,
          hashtags: hashtags,
          thumbnail_image_url: images[0],
          image_urls: images,
        })
        : handleUploadFeed({
          topic: topicValues[currentTopic],
          content: content,
          latitude: 0,
          longitude: 0,
          city: "대전",
          county: "구",
          hashtags: hashtags,
          thumbnail_image_url: images[0],
          image_urls: images,
        })): Toast.show({
          type: "error",
          text1: "토픽을 선택해 주세요!",
          visibilityTime: 1000,
          autoHide: true,
          bottomOffset: 40,
          position: "top",
        }) ;
      }}
    >
      <ScrollView style={{ flex: 1, maxHeight: SCREEN_HEIGHT - 56 }}>
        <View
          style={{
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "flex-start",
          }}
        >
          <Topic
            onPress={() => {
              modalRef.current.open();
            }}
          />
          <View
            style={{
              alignSelf: "center",
            }}
          >
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              alignSelf: "flex-start",
              marginBottom: 20,
              marginTop: 20,
              marginLeft: 20,
              width:335
            }}
          >
            <Profile image={userInfo.image_url ? userInfo.image_url:''} />
            <Text
              style={{
                fontSize: 15,
                justifyContent: "flex-start",
                marginLeft: 8,
              }}
              bold
            >
              {userInfo.name}
            </Text>
            </View>
          </View>
          <TextInput
            key={"content"}
            multiline={true}
            numberOfLines={8}
            value={content}
            onChangeText={(text) => setContent(text)}
            style={{ width: 335, height: 177,  }}
            placeholder={
              "오늘은 어떤 피드를 공유하고 싶으신가요?\n 당신의 피드가 기다려 집니다."
            }
          />
          <Tags top={0} bottom={10} tags={hashtags} />
          <Divider />
          <ImageBlocks images={images} style={{ flex: 1 }} />
          <Divider />
          {/* <TagInput
            value={tags}
            onChange={(tag) => setTags(text)}
            labelExtractor={(tag) => tag}
            text={this.state.text}
            onChangeText={(text) => this.setState({ text })}
          /> */}

          <TextInput
            key={"tag"}
            numberOfLines={1}
            value={hashtagText}
            onChangeText={(text) => { setHashtagText(text), setHashtags(text.split(','))}}
            style={{ width: 335, height: 44 }}
            placeholder={"태그를 추가해보세요! 서초동, 와린이, 돔페르뇽"}
          />
          <Divider />
        </View>
      </ScrollView>
      <BottomModal />
    </Layout>
  );
}

const styles = StyleSheet.create({
  wrapper: {},
  slide1: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
  },
  slide2: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
  },
  slide3: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
  },
  paginationStyle: {
    flex: 1,
    flexDirection: "row",
    position: "absolute",
    top: 0,
    justifyContent: "flex-start",
    alignSelf: "center",
  },
  buttonText: {
    position: "absolute",
    top: 0,
    alignSelf: "center",
    fontSize: 14,
    color: Colors.primary,
  },
  listitem: {
    backgroundColor: Colors.darkBlack,
    borderColor: Colors.redHigh,
    borderBottomWidth: 1,
    height: 40,
  },
  listContent: {
    backgroundColor: Colors.darkBlack,
  },
});
