import React from 'react';
import { Text } from 'react-native';
import Colors from '../../constants/colors';
export default function (props) {
	const B = (props) => <Text style={{fontWeight: 'bold'}}>{props.children}</Text>
	const C = (props) => <Text style={{color:Colors.primary}}>{props.children}</Text>
	return (
		<Text
			{...props}
			style={[
				props.style,
				{
					fontFamily: props.bold
						? 'NotoB'
						: props.medium
						? 'NotoR'
						: 'NotoR',
				},
			]}
		/>
	);
}
