import { StatusBar } from "expo-status-bar";
import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  Dimensions,
  Image,
} from "react-native";
import { ScaledSheet } from "react-native-size-matters";
import { nFormatter, numberFormat } from "../components/utils/Numbers";
import { getToken } from "../components/utils/Token";

import Layout from "../components/global/Layout";
import Text from "../components/utils/NotoFont";
import Colors from "../constants/colors";
import icons from "../constants/icons";
import {
  Profile,
} from "../components/global/ListItem";
import { TextInput } from "react-native-paper";
import { AuthContext } from "../provider/AuthProvider";
import env from "../constants/env";
import Toast from "react-native-toast-message";
import * as ImagePicker from "expo-image-picker";
import {uploadSingleImage} from "../components/utils/UploadImage";

export default function ({ navigation }) {
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get(
    "window"
  );
  const { logout } = useContext(AuthContext);
  const { userInfo } = useContext(AuthContext);
  const [introduction, setIntroduction] = useState(null);
  const [userImageUrl, setUserImageUrl] = useState(null);

  useEffect(() => {
    setIntroduction(userInfo.introduction);
    setUserImageUrl(userInfo.image_url);
  },[])


  const FollowingBlocks = (props) => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "column",
          height: 50,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ color: Colors.gray, fontSize: 14 }}>{props.name}</Text>
        <Text style={{ color: Colors.gray, fontSize: 18 }} bold>
          {nFormatter(props.value)}
        </Text>
      </View>
    );
  };

  const FollowingSection = (props) => {
    return (
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          marginTop: 16,
          marginBottom: 16,
        }}
      >
        <FollowingBlocks name={"팔로워"} value={props.followers} />
        <FollowingBlocks name={"팔로잉"} value={props.following} />
        <FollowingBlocks name={"참여한 치팅"} value={props.cheetings} />
      </View>
    );
  };

  const LinkBlock = (props) => {
    return (
      <TouchableOpacity
        style={{
          flex: 1,
          flexDirection: "row",
          marginBottom: 9,
          alignItems: "center",
        }}
      >
        <Image style={{ width: 15, height: 15 }} source={props.image} />
        <Text style={{ fontSize: 12, color: Colors.gray, marginLeft: 6 }}>
          {props.text}
        </Text>
      </TouchableOpacity>
    );
  };

  const LinkBlocks = (props) => {
    return (
      <View style={{ marginTop: 16, flexDirection: "column" }}>
        <LinkBlock image={icons.facebook} text={props.facebook} />
        <LinkBlock image={icons.instagram} text={props.instagram} />
        <LinkBlock image={icons.link} text={props.link} />
      </View>
    );
  };

  const EditButton = () => {
    return (
      <TouchableOpacity
        onPress={() => {
          console.log("Edit");
        }}
        style={{
          marginTop: 16,
          width: "100%",
          height: 32,
          borderColor: Colors.gray,
          borderRadius: 4,
          borderWidth: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontSize: 14, alignSelf: "center" }}>
          {"프로필 수정"}
        </Text>
      </TouchableOpacity>
    );
  };
  const LogOutButton = () => {
    return (
      <TouchableOpacity
        onPress={logout}
        style={{
          marginTop: 16,
          width: 350,
          height: 32,
          borderColor: Colors.gray,
          borderRadius: 4,
          borderWidth: 1,
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
        }}
      >
        <Text style={{ fontSize: 14}}>{"로그아웃"}</Text>
      </TouchableOpacity>
    );
  };

  const pickImage = async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1],
      quality: 1,
      base64:true
    });
  
    if (!result.cancelled) {
      let imageUrl = await uploadSingleImage(result, `https://${env.apiUrl}/api/v1/s3/upload/image`);
      console.log(imageUrl);
      setUserImageUrl(imageUrl);
    }
  };
  
  //Render Items
  return (
    <Layout
      navigation={navigation}
      withLogo
      withBack
      title="내 프로필"
      size={24}
      buttonB
      textB={"저장"}
      buttonBActive={true}
      onPressB={() => {
        updateUserInfo({ image_url: userImageUrl, introduction: introduction });
      }}
    >
      <View
        style={{
          flex: 1,
          backgroundColor: Colors.backgroundGray,
        }}
        showsVerticalScrollIndicator={false}
      >
        {userInfo && (
          <View
            style={{
              flexDirection: "column",
              marginTop: 1,
              width: 375,
              height: 283,
              alignSelf: "center",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: Colors.white,
            }}
          >
            <View style={{ marginTop: 30 }}>
              <TouchableOpacity
                onPress={() => {
                  pickImage();
                }}
              >
                <Profile image={userImageUrl} size={72} />
              </TouchableOpacity>
              <Text style={{ fontSize: 14, alignSelf: "center" }} bold>
                {userInfo.name}
              </Text>
            </View>
            <Text style={{marginTop:30, alignSelf: "flex-start", marginLeft:20}}>{"한 줄 자기소개"}</Text>
            <TextInput
              style={{
                width: 335,
                heigth: 68,
                color: Colors.B_01,
              }}
              selectionColor={Colors.primary}
              outlineColor={Colors.B_06}
              mode="outlined"
              dense
              value={introduction}
              onChangeText={(text) => {
                setIntroduction(text);
              }}
              placeholder={"자기소개를 입력해보세요!"}
            />
          </View>
        )}
        {userInfo && (
          <View
            style={{
              width: 375,
              marginTop: 12,
              alignItems: "center",
              justifyContent: "center",
              alignSelf: "center",
              backgroundColor: Colors.white,
            }}
          >
            <View
              style={{
                width: 335,
                height: 56,
                marginBottom: 1,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{ color: Colors.B_02, width: 56, marginRight: 12 }}
                bold
              >
                {"연락처"}
              </Text>
              <Text style={{ color: Colors.B_04 }}>
                {userInfo.phone_number}
              </Text>
            </View>
            <View
              style={{
                width: 335,
                height: 56,
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Text
                style={{ color: Colors.B_02, width: 56, marginRight: 12 }}
                bold
              >
                {"이메일"}
              </Text>
              <Text style={{ color: Colors.B_04 }}>{userInfo.email}</Text>
            </View>
          </View>
        )}
        <LogOutButton />
      </View>
    </Layout>
  );
}

async function getUserInfo() {
  // await handleUploadImage();
  const result = await fetch(`https://${env.apiUrl}/api/v1/user/profile`, {
    method: "GET",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
  })
    .then((res) => res.json())
    .then((json) => {
      return json.data;
    })
    .catch((error) => {
      Toast.show({
        type: "error",
        text1: "네트워크 에러 발생!",
        visibilityTime: 1000,
        autoHide: true,
      });

      console.error(error);
    });
  console.log(result);
  return result;
}

async function updateUserInfo(data) {
  // await handleUploadImage();
  fetch(`https://${env.apiUrl}/api/v1/user/profile`, {
    method: "PUT",
    mode: "cors",
    headers: {
      "Content-Type": "application/json",
      Authorization: await getToken(),
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      res.status;
      console.log(res.status);
      console.log(res);
      res.status === 202 &&
        Toast.show({
          type: "success",
          text1: "업데이트 완료 !",
          visibilityTime: 1000,
          autoHide: true,
        });
    })
    .catch((error) => {
      Toast.show({
        type: "success",
        text1: "업데이트 완료 !",
        visibilityTime: 1000,
        autoHide: true,
      });

      console.error(error);
    });
}

const styles = ScaledSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
  },
  reservedSongSection: {
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  reservedSongListContainer: {
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 23,
  },
});
