import { StatusBar } from "expo-status-bar";
import React, { useState, useRef, useEffect, useContext } from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  // FlatList,
  Image,
  Dimensions,
} from "react-native";
import icons from "../constants/icons";
import * as firebase from "firebase";
import Modal from "react-native-modalbox";
import { AuthContext } from "../provider/AuthProvider";
import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import {
  FeedProfile,
  Tags,
  SendComment,
  ReplyListItem,
  Divider,
} from "../components/global/ListItem";
import Text from "../components/utils/NotoFont";
import AutoHeightImage from "react-native-auto-height-image";
import image from "../assets/404.png";
import env from "../constants/env";
import { getToken } from "../components/utils/Token";
import {
  handleLike,
  handleReply,
  getReply,
  updateReply,
  deleteReply,
} from "../components/utils/communityFunctions";
import Toast from "react-native-toast-message";

export default function ({ route, navigation }) {
  const { community_id = null } = route.params ?? {};
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get(
    "window"
  );
  const { userInfo } = useContext(AuthContext);

  const [replyList, setReplyList] = useState([]);

  const [feed, setFeed] = useState();
  async function getCommunityDetail() {
    await fetch(`https://${env.apiUrl}/api/v1/community/${community_id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json.data);
        setFeed(json.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    (async () => {
      console.log("GET DETAIL!");
      await getCommunityDetail();
      let reply = await getReply("community_id", community_id);
      console.log(reply);
      setReplyList(reply);
    })();
  }, []);


  async function deleteCommunity() {
    await fetch(`https://${env.apiUrl}/api/v1/community/${community_id}`, {
      method: "DELETE",
      mode: "cors",
      headers: {
        Authorization: await getToken(),
      },
    })
    .then((res) => {
      res.status;
      console.log(res.status);
      console.log(res);
      res.status === 202
        ? (Toast.show({
            type: "success",
            text1: "글 삭제가 완료 되었습니다!",
            visibilityTime: 1000,
            autoHide: true,
          }),
          navigation.navigate('Community'))
        : Toast.show({
            type: "error",
            text1: "에러발생 !",
            visibilityTime: 1000,
            autoHide: true,
          });
    })
    .catch((error) => {
      (Toast.show({
        type: "success",
        text1: "글 삭제가 완료 되었습니다!",
        visibilityTime: 1000,
        autoHide: true,
      }),
      navigation.navigate('Community'))
      console.error(error);
    });
  }
  const [message, setMessage] = useState("");
  const inputRef = useRef();

  const updateLike = () => {
    console.log(feed.whether_liked);
    setFeed({
      ...feed,
      whether_liked: !feed.whether_liked,
      like_cnt: feed.whether_liked ? feed.like_cnt - 1 : feed.like_cnt + 1,
    });
  };
  const Content = (props) => {
    return (
      <View
        style={{
          flex: 1,
          marginTop: 32,
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Text style={{ fontSize: 14 }}>{props.content}</Text>
        <View style={{ marginTop: 12, flexDirection: "column" }}>
          {props.images.map((image, index) => {
            return (
              <View
                key={index}
                style={{
                  flex: 1,
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  marginVertical: 8,
                }}
              >
                <AutoHeightImage
                  width={343}
                  source={{ uri: image }}
                  fallbackSource={image}
                />
              </View>
            );
          })}
        </View>
      </View>
    );
  };

  const LikeSection = () => {
    return (
      <View
        style={{
          width: 375,
          height: 46,
          borderTopWidth: 1,
          borderColor: Colors.gray,
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <TouchableOpacity
          style={{
            flexDirection: "row",
            width: 335,
            height: 20,
            alignItems: "center",
          }}
          onPress={() => {
            updateLike(), handleLike({ community_id: community_id });
          }}
        >
          <Image
            style={{ width: 20, height: 20 }}
            source={feed.whether_liked ? icons.likeA : icons.like}
          />
          <Text style={{ fontSize: 14, color: Colors.gray, marginLeft: 20 }}>
            {"좋아요 " + feed.like_cnt}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const CommentSection = () => {
    return (
      <View
        style={{
          width: 375,
          height: 46,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: Colors.gray,
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: 335,
            height: 20,
            alignItems: "center",
          }}
        >
          <Image style={{ width: 20, height: 20 }} source={icons.comment} />
          <Text style={{ fontSize: 14, color: Colors.gray, marginLeft: 20 }}>
            {"댓글 " + feed.reply_cnt}
          </Text>
        </View>
      </View>
    );
  };

  const Comments = () => {
    return (
      <View
        style={{
          marginTop: 12,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        {replyList.length > 0 &&
          replyList.map((item) => {
            return (
              <ReplyListItem
                key={item.reply_id}
                date={item.updated_at}
                text={item.text}
                image={item.user.image_url}
                name={item.user.name}
              />
            );
          })}
      </View>
    );
  };
  const TopicBlock = (props) => {
    return (
      <View style={{ width: 375, height: 112, flexDirection: "column", alignItems: "center" }}>
        <TouchableOpacity
          onPress={() => {
            navigation.navigate('CreateCommunity', {
              community_id: community_id,
            })
            modalRef.current.close();
          }}
          style={{
            width: 335,
            height: 56,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Image source={icons.editCommunity} style={{ width: 24, height: 24, alignSelf: "center" }} />
          <Text
            style={{
              fontSize: 14,
              color: Colors.gray,
              alignSelf: "center",
            }}
          >
            {"피드 수정하기"}
          </Text>
        </TouchableOpacity>
        <TouchableOpacity
          onPress={() => {
            deleteCommunity();
            modalRef.current.close();
          }}
          style={{
            width: 375,
            height: 56,
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "row",
          }}
        >
          <Image source={icons.trash} style={{ width: 24, height: 24 ,alignSelf: "center"}} />
          <Text
            style={{
              fontSize: 14,
              color: Colors.gray,
              alignSelf: "center"
            }}
          >
            {"피드 삭제하기"}
          </Text>
        </TouchableOpacity>

      </View>
    );
  };
  const modalRef = useRef(null);

  const BottomModal = () => {
    return (
      <Modal
        position={"bottom"}
        style={{
          backgroundColor: Colors.white,
          justifyContent: "center",
          alignItems: "center",
          width: 375,
          height: 112,
        }}
        ref={modalRef}
        swipeToClose={true}
        // onClosed={onClose}
        // onOpened={onOpen}
      >
        <TopicBlock />
      </Modal>
    );
  };

  return (
    <Layout
      navigation={navigation}
      size={24}
      withBack
      title={feed ? feed.user.name + "님의 게시물" : "" + "님의 게시물"}
      buttonB
      imageB={feed && (feed.mine && icons.moreH)}
      onPressB={() => {
        feed && (feed.mine && modalRef.current.open());
      }}
    >
      {feed && (
        <ScrollView style={{ flex: 1, maxHeight: SCREEN_HEIGHT - 56 }}>
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <View
              style={{
                flex: 1,
                minHeight: 42,
                maxHeight: 42,
                width: 343,
                marginTop: 16,
              }}
            >
              <FeedProfile
                name={feed ? feed.user.name : ""}
                profile={feed ? feed.user.image_url : ""}
                date={feed ? feed.created_at : ""}
              />
            </View>
            <View style={{ flex: 20, width: 343 }}>
              <Content content={feed.content} images={feed.image_urls} />
            </View>
            <View style={{ flex: 1, width: 343, marginBottom: 30 }}>
              <Tags top={0} bottom={0} tags={feed.hashtags} />
            </View>
          </View>
          <View style={{ width: 375, marginBottom: 46, alignSelf: "center"}}>
            <LikeSection />
            <CommentSection />
            <SendComment
            image={userInfo.image_url}
          message={message}
          isActive={message.length > 0 ? true : false}
          sendMessage={async () => {
            handleReply({ text: message, community_id: community_id }),
              setReplyList(await getReply("community_id", community_id));
            setMessage("");
          }}
          onChangeText={(text) => {
            setMessage(text);
          }}
        />
            {Comments()}
          </View>
        </ScrollView>
      )}
      <BottomModal />
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: Colors.backgroundGray,
    top: 25,
    marginBottom: 25,
  },
  horizontalContainer: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  cardContainer: {
    top: 100,
    width: "98%",
    left: "1%",
  },
  cardList: {
    margin: 15,
    width: "100%",
  },
  userInfo: {
    top: 50,
    left: 50,
    flex: 1,
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: 50,
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: 30,
    fontWeight: "bold",
    fontSize: 30,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: 20,
    fontWeight: "bold",
  },
  sectionBar: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    minWidth: 372,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderColor: "#DDDDDD",
    minWidth: "100%",
    alignItems: "center",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
});
