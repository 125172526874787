import 'intl';
import 'intl/locale-data/jsonp/ko'; // or any other locale you need

export function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "천" },
      { value: 1e4, symbol: "만" },
      { value: 1e8, symbol: "억" },
      { value: 1e11, symbol: "조" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

  
export const numberFormat = (value) =>
new Intl.NumberFormat("ko-KR", {
  style: "currency",
  currency: "KRW",
}).format(value);
