import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import {
  StyleSheet,
  TouchableOpacity,
  View,
  ScrollView,
  Image,
  Dimensions,
  ImageBackground,
  Platform,
} from "react-native";
import icons from "../constants/icons";

import Layout from "../components/global/Layout";
import Colors from "../constants/colors";
import {
  Tags,
  SendComment,
  InfoListItem,
  CheetingMemberListItem,
  ReplyListItem,
} from "../components/global/ListItem";
import Text from "../components/utils/NotoFont";
import Modal from "react-native-modalbox";
import { WebView } from "react-native-webview";
import { AuthContext } from "../provider/AuthProvider";
import Toast from "react-native-toast-message";
import env from "../constants/env";
import { getToken } from "../components/utils/Token";
import { RefreshControl } from "react-native-web-refresh-control";
import * as Location from "expo-location";
import MapView, { Marker } from "react-native-maps";
import {
  handleLike,
  handleReply,
  getReply,
  updateReply,
  deleteReply,
} from "../components/utils/communityFunctions";

export default function ({ route, navigation }) {
  const { cheeting_id = null } = route.params ?? {};
  const [refreshing, setRefreshing] = useState(false);
  const { userInfo } = useContext(AuthContext);
  const { width: SCREEN_WIDTH, height: SCREEN_HEIGHT } = Dimensions.get(
    "window"
  );
  // const { userInfo } = useContext(AuthContext);
  const [cheeting, setCheeting] = useState(null);
  const screen = Dimensions.get("window");
  const ASPECT_RATIO = screen.width / screen.height;
  const [currentWine, setCurrentWine] = useState(0);

  const LATITUDE_DELTA = 0.03;
  const LONGITUDE_DELTA = LATITUDE_DELTA * ASPECT_RATIO;
  const [currentRegion, setCurrentRegion] = useState(null);
  const [replyList, setReplyList] = useState([]);

  const reloadList = useCallback(() => {
    setRefreshing(true);

    getCheetingCandidates().then(() => {
      setRefreshing(false);
    });
  }, []);

  const [location, setLocation] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [address, setAddress] = useState(null);
  useEffect(() => {
    (async () => {
      let { status } = await Location.requestForegroundPermissionsAsync();
      if (status !== "granted") {
        setErrorMsg("Permission to access location was denied");
        return;
      }

      let loc = await Location.getCurrentPositionAsync({});
      setLocation(loc);
      console.log(loc);
    })();
  }, []);

  async function getCheetingDetail() {
    await fetch(`https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}`, {
      headers: {
        Authorization: await getToken(),
      },
    })
      .then((res) => res.json())
      .then((json) => {
        setCheeting(json.data);
        setCurrentRegion({
          latitude: json.data.latitude,
          longitude: json.data.longitude,
          latitudeDelta: LATITUDE_DELTA,
          longitudeDelta: LONGITUDE_DELTA,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function getCheetingCandidates() {
    await fetch(
      `https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}/candidates`,
      {
        headers: {
          Authorization: await getToken(),
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setPeopleList(json.data);
        let approved = json.data.filter((item) => item.status === "APPROVED");
        let waiting = json.data.filter((item) => item.status !== "APPROVED");
        console.log("승인됨:", approved, "대기중:", waiting);
        setConfirmedList(approved);
        setWaitingList(waiting);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function applyCheeting() {
    await fetch(
      `https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}/candidates`,
      {
        method: "POST",
        mode: "cors",
        headers: {
          Authorization: await getToken(),
        },
      }
    )
      .then((res) => {
        res.status === 201
          ? Toast.show({
              type: "success",
              text1: "참가신청되었습니다!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            })
          : res.status === 403
          ? Toast.show({
              type: "error",
              text1: "이미 참가중입니다!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            })
          : Toast.show({
              type: "error",
              text1: "참가신청 실패!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function acceptMember(userId) {
    await fetch(
      `https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}/candidates`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: await getToken(),
        },
        body: JSON.stringify({ user_id: userId, status: "APPROVED" }),
      }
    )
      .then((res) => {
        res.status === 202
          ? (Toast.show({
              type: "success",
              text1: "수락되었습니다!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            }),
            updateUserList(member.user_id, true))
          : res.status === 403
          ? Toast.show({
              type: "error",
              text1: "자신의 치팅이 아닙니다!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            })
          : Toast.show({
              type: "error",
              text1: "수락 실패!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function rejectMember(userId) {
    await fetch(
      `https://${env.apiUrl}/api/v1/cheeting/${cheeting_id}/candidates`,
      {
        method: "PUT",
        mode: "cors",
        headers: {
          Authorization: await getToken(),
        },
        body: JSON.stringify({ user_id: userId, status: "REJECTED" }),
      }
    )
      .then((res) => {
        res.status === 202
          ? (Toast.show({
              type: "success",
              text1: "거절하였습니다!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            }),
            updateUserList(member.user_id, false))
          : res.status === 403
          ? Toast.show({
              type: "error",
              text1: "자신의 치팅이 아닙니다!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            })
          : Toast.show({
              type: "error",
              text1: "거절 실패!",
              visibilityTime: 1000,
              autoHide: true,
              bottomOffset: 40,
              position: "top",
            });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    (async () => {
      console.log("GET DETAIL!");
      await getCheetingDetail();
      await getCheetingCandidates();
      let reply = await getReply("cheeting_id", cheeting_id);
      console.log(reply);
      setReplyList(reply);
    })();
  }, []);

  const numberFormat = (value) =>
    new Intl.NumberFormat("ko-KR", {
      currency: "KRW",
    }).format(value);

  const wineModalRef = useRef();

  const feedSampleList = [
    require("../assets/sample/cheeting/1.json"),
    require("../assets/sample/cheeting/2.json"),
    require("../assets/sample/cheeting/3.json"),
  ];
  const peopleListSample = [
    require("../assets/sample/cheeting/1_list.json"),
    require("../assets/sample/cheeting/2_list.json"),
    require("../assets/sample/cheeting/3_list.json"),
  ];

  const [message, setMessage] = useState("");
  const [peopleList, setPeopleList] = useState();
  const [confirmedList, setConfirmedList] = useState([]);
  const [waitingList, setWaitingList] = useState([]);

  function updateUserList(user, APPROVED) {
    console.log("APPROVED?", APPROVED);
    let array = [...peopleList];
    array = array.map((item) =>
      item.user_id === user
        ? { ...item, status: APPROVED ? "APPROVED" : "REJECTED" }
        : item
    );
    console.log("user", user, array);
    setPeopleList(array);
    const approved = array.filter((item) => item.status === "APPROVED");
    const waiting = array.filter((item) => item.status !== "APPROVED");
    console.log("승인됨:", approved, "대기중:", waiting);
    setConfirmedList(approved);
    setWaitingList(waiting);
  }

  const Profile = (props) => {
    return (
      <View>
        {props.withBadge ? (
          <View style={{ flex: 1, top: -20 }}>
            <Image
              source={require("../assets/crown.png")}
              style={{
                width: 24,
                height: 24,
                alignSelf: "flex-start",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                top: 14,
                left: -4,
                zIndex: 1,
              }}
            />
            <Image
              source={
                props.image
                  ? { uri: props.image }
                  : require("../assets/icon.png")
              }
              style={{ width: 72, height: 72, borderRadius: 36 }}
            />
          </View>
        ) : (
          <View>
            <Image
              source={
                props.image
                  ? { uri: props.image }
                  : require("../assets/icon.png")
              }
              style={{ width: 54, height: 54, borderRadius: 27 }}
            />
          </View>
        )}
      </View>
    );
  };

  const CoverImage = (props) => {
    return (
      <ImageBackground
        resizeMode="cover"
        style={{ width: 375, height: 210 }}
        source={
          props.coverImage
            ? { uri: props.coverImage }
            : require("../assets/defaultCover.jpg")
        }
      >
        <View
          style={{
            flex: 1,
            top: 128,
            flexDirection: "row",
            alignItems: "flex-end",
            justifyContent: "center",
          }}
        >
          <ProfileContent
            cheerLeaderName={props.cheerLeaderName}
            profile={props.profile}
            review={5}
            ratings={5}
          />
        </View>
      </ImageBackground>
    );
  };

  const ProfileContent = (props) => {
    return (
      <View
        style={{
          maxHeight: 100,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <Profile image={props.profile} />
        <Text
          style={{ fontSize: 14, color: Colors.B_02 }}
          numberOfLines={1}
          bold
        >
          {props.cheerLeaderName}
        </Text>
        <Text
          style={{ fontSize: 12, color: Colors.B_04 }}
          numberOfLines={1}
          bold
        >
          {"치어리더(주최자)"}
        </Text>
      </View>
    );
  };

  const Title = (props) => {
    return (
      <View
        style={{
          alignSelf: "center",
          width: 320,
          marginTop: 80,
          marginBottom: 10,
          height: 84,
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text
          numberOfLines={2}
          style={{ fontSize: 22, color: Colors.black, alignSelf: "center" }}
          bold
        >
          {props.title}
        </Text>
      </View>
    );
  };

  const SectionBar = (props) => {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: props.top,
          alignItems: "flex-start",
          width: 335,
          maxHeight: 48,
          borderBottomWidth: 1,
          borderBottomColor: Colors.lightGray,
        }}
      >
        <Text style={{ color: Colors.primary, fontSize: 14 }} bold>
          {props.title}
        </Text>
          {props.subtext !== undefined && <Text style={{ color: Colors.B_04, fontSize: 14}}>
            {props.subtext}
          </Text>
        }      
        </View>
    );
  };
  const Content = (props) => {
    return (
      <View
        style={{
          flex: 1,
          marginTop: 32,
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <Text style={{ fontSize: 14 }}>{props.content}</Text>
      </View>
    );
  };

  const ApplyCheeting = (props) => {
    return (
      <View
        style={{
          height: 52,
          width: 337,
          flexDirection: "row",
          alignSelf: "center",
          alignItems: "center",
          borderWidth: 2,
          borderColor: Colors.primary,
          backgroundColor: props.isMine ? Colors.white : Colors.primary,
          borderRadius: 4,
        }}
      >
        <TouchableOpacity
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          onPress={props.onPress}
        >
          <Text
            style={{
              color: props.isMine ? Colors.primary : Colors.white,
              fontSize: 18,
            }}
            bold
          >
            {props.text}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  function onOpen() {
    console.log("Modal just opened");
  }

  function onClose() {
    console.log("Modal just Closed");
  }

  const WineDetails = (props) => {
    return (
      <View
        style={{
          width: 255,
          height: 30,
          marginBottom: 16,
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Text style={{ fontSize: 14, color: Colors.darkgray }}>
          {props.text}
        </Text>
        <TouchableOpacity onPress={props.onPress}>
          <Text
            style={{
              fontSize: 12,
              color: Colors.lightGray,
              textDecorationLine: "underline",
            }}
          >
            {props.text2}
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const Preview = () => {
    return (
      <View
        style={{
          width: 295,
          height: 498,
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Image
          style={{ width: 295, height: 295, marginBottom: 30 }}
          source={
            cheeting.wines[currentWine].image_urls[0]
              ? { uri: cheeting.wines[currentWine].image_urls[0] }
              : require("../assets/wine_default.png")
          }
        />
        <View
          style={{
            flex: 1,
            height: 44,
            width: 255,
            alignSelf: "center",
            alignItems: "center",
            justifyContent: "center",
            borderBottomWidth: 1,
            borderBottomColor: Colors.gray,
          }}
        >
          <Text style={{ fontSize: 16, color: Colors.black }} bold>
            {cheeting.wines[currentWine].name}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            marginTop: 20,
            marginBottom: 20,
          }}
        >
          <WineDetails
            text={"포도 품종 : " + cheeting.wines[currentWine].grape_variety}
          />
          <WineDetails
            text={"빈티지 : " + cheeting.wines[currentWine].vintage}
          />
        </View>
      </View>
    );
  };

  const WineModal = (props) => {
    return (
      <Modal
        style={{
          position: "absolute",
          backgroundColor: Colors.white,
          justifyContent: "center",
          alignItems: "center",
          width: 295,
          height: 498,
        }}
        ref={wineModalRef}
        swipeToClose={false}
        onClosed={onClose}
        onOpened={onOpen}
      >
        {cheeting && Preview()}
      </Modal>
    );
  };
  const CommentSection = () => {
    return (
      <View
        style={{
          width: 375,
          height: 46,
          borderTopWidth: 1,
          borderBottomWidth: 1,
          borderColor: Colors.gray,
          justifyContent: "center",
          alignItems: "center",
          alignSelf: "center",
        }}
      >
        <View
          style={{
            flexDirection: "row",
            width: 335,
            height: 20,
            alignItems: "center",
          }}
        >
          <Image style={{ width: 20, height: 20 }} source={icons.comment} />
          <Text style={{ fontSize: 14, color: Colors.gray, marginLeft: 20 }}>
            {"댓글 " + replyList.length}
          </Text>
        </View>
      </View>
    );
  };

  const Comments = () => {
    return (
      <View
        style={{
          marginTop: 12,
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {replyList.length > 0 &&
          replyList.map((item) => {
            return (
              <ReplyListItem
                key={item.reply_id}
                date={item.updated_at}
                text={item.text}
                image={item.user.image_url}
                name={item.user.name}
              />
            );
          })}
      </View>
    );
  };

  const isCloseToBottom = ({
    layoutMeasurement,
    contentOffset,
    contentSize,
  }) => {
    const paddingToBottom = 20;
    return (
      layoutMeasurement.height + contentOffset.y >=
      contentSize.height - paddingToBottom
    );
  };

  const [hideOnBottom, setHideOnBottom] = useState(false);
  const [hideOnFocus, setHideOnFocus] = useState(false);

  const updateLike = () => {
    console.log(cheeting.whether_liked);
    setCheeting({
      ...cheeting,
      whether_liked: !cheeting.whether_liked,
    });
  };

  return (
    <Layout
      navigation={navigation}
      size={24}
      withBack
      title={cheeting ? cheeting.user.name + "님의 치팅" : "" + "님의 치팅"}
      buttonB
      imageB={icons.heart}
      imageBA={icons.heartA}
      buttonBActive={cheeting ? (cheeting.whether_liked ? true : false) : false}
      onPressB={() => {
        updateLike(), handleLike({ cheeting_id: cheeting_id });
      }}
    >
      <WineModal />
      <ScrollView
        style={{ flex: 1, maxHeight: SCREEN_HEIGHT - 56 }}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={reloadList} />
        }
        onScroll={({ nativeEvent }) => {
          if (isCloseToBottom(nativeEvent)) {
            setHideOnBottom(true);
          } else {
            setHideOnBottom(false);
          }
        }}
        scrollEventThrottle={400}
      >
        {cheeting ? (
          <View
            style={{
              flex: 1,
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <View style={{ flexWrap: "wrap", width: 375 }}>
              <CoverImage
                cheerLeaderName={cheeting.user.name}
                profile={cheeting.user.image_url}
                level={1}
                review={5}
                ratings={5}
                coverImage={cheeting.image_url}
              />
            </View>
            <View style={{ alignItems: "center", justifyContent: "center" }}>
              <Title title={cheeting.title} />
              <Tags top={0} bottom={20} tags={cheeting.hashtags} />
            </View>
            <SectionBar top={40} title={"모임안내"} />
            <View
              style={{
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "center",
                width: 335,
                paddingVertical: 6,
              }}
            >
              <InfoListItem
                isDate
                image={icons.calendarCheck}
                text={cheeting.start_at}
              />
              <InfoListItem
                image={icons.members}
                text={"정원 " + cheeting.people_limit + "명 (치어리더 제외)"}
              />
              <View style={styles.infoList}>
                <Image
                  style={{ width: 24, height: 24, marginRight: 10 }}
                  source={icons.wine}
                />
                <View style={{ flexDirection: "column" }}>
                  {cheeting.wines.map((item, index) => {
                    return (
                      <TouchableOpacity
                        key={index}
                        style={{ marginRight: 20 }}
                        onPress={() => {
                          setCurrentWine(index), wineModalRef.current.open();
                        }}
                      >
                        <Text
                          style={{
                            color: Colors.B_01,
                            textDecorationLine: "underline",
                          }}
                        >
                          {item.name != ""
                            ? item.name
                            : "와인을 찾을 수 없음" + (index > 0 ? "," : "")}
                        </Text>
                      </TouchableOpacity>
                    );
                  })}
                </View>
              </View>

              <InfoListItem
                image={icons.won}
                text={numberFormat(cheeting.participation_fee) + "(1인)"}
              />
              {confirmedList && (
                <InfoListItem
                  isChat
                  canView={
                    cheeting.mine ||
                    confirmedList.filter(
                      (item) => item.user_id === userInfo.user_id
                    ) > 0
                  }
                  image={icons.openchat}
                  text={
                    cheeting.open_chat_link
                      ? cheeting.open_chat_link
                      : "https://open.kakao.com/o/gNllAwCd"
                  }
                />
              )}
              <InfoListItem image={icons.map} text={cheeting.place_name} />
              {currentRegion && cheeting && (
                <MapView
                  style={{ width: 335, height: 200 }}
                  initialRegion={currentRegion}
                >
                  {cheeting && Platform.OS !== "web" && (
                    <Marker
                      coordinate={{
                        latitude: cheeting.latitude,
                        longitude: cheeting.longitude,
                      }}
                      title={cheeting.place_name}
                    />
                  )}
                </MapView>
              )}
            </View>
            <SectionBar top={54} title={"치어리더's Note"} />
            <View style={{ flex: 20, width: 343 }}>
              <Content content={cheeting.introduction} />
            </View>

            <SectionBar
              top={54}
              title={"참석 확정"}
              subtext={confirmedList.length + "명"}
            />
            <View style={{ paddingVertical: 12 }}>
              {confirmedList.length > 0 ? (
                confirmedList.map((member, index) => {
                  return (
                    <CheetingMemberListItem
                      key={index}
                      profile={member.image_url}
                      name={member.name}
                      introduction={member.introduction}
                    />
                  );
                })
              ) : (
                <View>
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.gray,
                      // alignSelf: "left",
                    }}
                  >
                    {
                      "아직 참석 확정자가 없습니다. \n모임에 지원하고, 첫번째 참가자가 되어보세요!"
                    }
                  </Text>
                </View>
              )}
            </View>
            <SectionBar
              top={54}
              title={"참석 대기"}
              subtext={waitingList.length + "명"}
            />
            <View style={{ paddingVertical: 12, marginBottom: 54 }}>
              {waitingList.length > 0 ? (
                waitingList.map((member, index) => {
                  return (
                    <CheetingMemberListItem
                      key={index}
                      profile={member.image_url}
                      name={member.name}
                      introduction={member.introduction}
                      rating={member.rating}
                      isWaiting
                      isMine={cheeting ? cheeting.mine : false}
                      rejected={member.status === "REJECTED" ? true : false}
                      buttonA={() => {
                        acceptMember(member.user_id);
                      }}
                      buttonB={() => {
                        rejectMember(member.user_id);
                      }}
                    />
                  );
                })
              ) : (
                <View>
                  <Text
                    style={{
                      fontSize: 14,
                      color: Colors.gray,
                      // alignSelf: "left",
                    }}
                  >
                    {
                      "아직 참석 대기자가 없습니다.\n아래 버튼을 눌러, 첫번째 지원자가 되어보세요!"
                    }
                  </Text>
                </View>
              )}
            </View>
          </View>
        ) : (
          <View></View>
        )}
        <CommentSection />
        <SendComment
          onFocus={() => {
            setHideOnFocus(true), console.log("Hide!!");
          }}
          onBlur={() => {
            setHideOnFocus(false), console.log("Show!!");
          }}
          image={userInfo.image_url}
          message={message}
          isActive={message.length > 0 ? true : false}
          sendMessage={async () => {
            handleReply({ text: message, cheeting_id: cheeting_id }),
              setReplyList(await getReply("cheeting_id", cheeting_id));
            setMessage("");
          }}
          onChangeText={(text) => {
            setMessage(text);
          }}
        />
        {Comments()}
      </ScrollView>
      {!hideOnBottom && !hideOnFocus && (
        <View
          style={{
            position: "absolute",
            alignSelf: "center",
            width: 335,
            height: 72,
            bottom: "0%",
          }}
        >
          {cheeting && (
            <ApplyCheeting
              text={cheeting.mine ? "글 수정하기" : "모임 참석하기"}
              isMine={cheeting.mine}
              onPress={() => {
                console.log("모임참석하기");
                cheeting.mine
                  ? navigation.navigate("CreateCheeting", {
                      cheeting_id: cheeting_id,
                    })
                  : applyCheeting();
              }}
            />
          )}
        </View>
      )}
    </Layout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignSelf: "center",
    backgroundColor: Colors.backgroundGray,
    top: 25,
    marginBottom: 25,
  },
  horizontalContainer: {
    flex: 1,
    flexDirection: "row",
    flexGrow: 1,
  },
  cardContainer: {
    top: 100,
    width: "98%",
    left: "1%",
  },
  cardList: {
    margin: 15,
    width: "100%",
  },
  userInfo: {
    top: 50,
    left: 50,
    flex: 1,
    flexDirection: "column",
  },
  userInfoTitle: {
    fontSize: 50,
    fontWeight: "bold",
  },
  userInfoClass: {
    marginTop: 30,
    fontWeight: "bold",
    fontSize: 30,
  },
  title: {
    justifyContent: "center",
    alignItems: "center",
    color: Colors.footerText,
    fontSize: 20,
    fontWeight: "bold",
  },
  sectionBar: {
    borderBottomWidth: 1,
    borderColor: Colors.lightGray,
    minWidth: 372,
  },
  sectionDivider: {
    borderBottomWidth: 1,
    borderColor: "#DDDDDD",
    minWidth: "100%",
    alignItems: "center",
  },
  contentContainer: {
    justifyContent: "center",
    alignItems: "center",
  },
  infoList: {
    flexDirection: "row",
    width: 335,
    paddingVertical: 6,
    justifyContent: "flex-start",
    alignItems: "center",
  },
});
