import AsyncStorage from "@react-native-async-storage/async-storage";

export async function saveToken(value) {
  console.log("Save Token", value);
  try {
    await AsyncStorage.setItem("@token", value);
    console.log("Token Saved Successfully");
  } catch (e) {
    console.log(e);
    // saving error
  }
}

export async function getToken() {
  try {
    const value = await AsyncStorage.getItem("@token");
    if (value !== null) {
      // value previously stored
      console.log("getToken", value);
      return value;
    }
  } catch (e) {
    // error reading value
    console.log("No token Found!");
    console.log(e);
  }
}

export async function getUser() {

    console.log("getUser!");
    try {
      fetch(`https://${env.apiUrl}/api/v1/user/profile`, {
        method: "GET",
        mode: "cors",
        headers: {
          Authorization: await getToken(),
        },
      })
    .then((response) => response.json())
		.then((data) => {

      return(data);
			})
    .catch((error) => {console.log(error);
      return(null);
		});
    } catch {
      (e) => console.log(e);
    }
}